/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable operator-assignment */
/* eslint-disable no-plusplus */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react'
import './style/reportPdf.scss'
import * as Highcharts from 'highcharts'
import HC_EXPORTS from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import offlineExporting from 'highcharts/modules/offline-exporting'
import HCMore from 'highcharts/highcharts-more'
import axios from 'axios'

HC_EXPORTS(Highcharts)
HCMore(Highcharts)

interface ArchiveGraphProp {
  id?: any
  graphid?: any
  progressaverage?: any
  progresscolor?: any
  onchartUpdate?: any
}

export const Achivementgraph: React.FC<ArchiveGraphProp> = ({
  id,
  graphid,
  progressaverage,
  progresscolor,
  onchartUpdate,
}) => {
  const [progressPercent] = useState((progressaverage * 100) / 6)
  const [remaingPercent] = useState(100 - progressPercent)
  const chartComponent = useRef(null)
  const [options, achivesetOptions] = useState<Highcharts.Options>({})
  const seriesDatat: any = []
  // const [nav] =  Highcharts.win.navigator;
  // const [oldDownloadURL] = Highcharts.downloadURL;

  useEffect(() => {
    const optionals: Highcharts.Options = {
      exporting: {
        //   sourceWidth:400,
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
        chartOptions: {
          // specific options for the exported image
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        fallbackToExportServer: false,
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: 'pie',
        renderTo: 'container',
      },
      title: {
        verticalAlign: 'middle',
        floating: true,
        text: `${progressPercent.toPrecision(2)}%`,
        style: {
          color: `${progresscolor}`,
          fontWeight: 'bold',
          fontSize: '45px',
        },
      },
      plotOptions: {
        pie: {
          innerSize: '60%',
        },
      },
      series: [
        {
          type: 'pie',
          data: [
            [0, progressPercent],
            [0, remaingPercent],
          ],
          colors: [progresscolor, ''],
          dataLabels: {
            enabled: false,
          },
        },
      ],
    }
    achivesetOptions(optionals)
    afterachiveChartCreated(optionals)
  }, [])

  const afterachiveChartCreated = async (optionals: any) => {
    // const exportUrl = 'https://export.highcharts.com/';
    // const exportUrl = 'http://127.0.0.1:7801/';

    const exportUrl = 'https://export.growmetrix.com/'

    const object = {
      infile: JSON.stringify(optionals),
      scale: '2',
      constr: 'Chart',
      b64: true,
      width: 400,
      type: 'image/png',
    }
    await axios.post(exportUrl, object).then(async (res) => {
      onchartUpdate({ id, image: `data:image/png;base64,${res.data}` })
    })
  }

  return (
    <>
      <div className="statsbody d-flex justify-content-around mt-0">
        <div className="pie mt-0 ms-0">
          <div className="position-relative" id={graphid}>
            <HighchartsReact
              ref={chartComponent}
              highcharts={Highcharts}
              options={options}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default Achivementgraph

import React, { useState } from 'react'
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import { logout } from '../../api/API'
import { eraseCookie, readCookie } from '../../api/CookieScript'
import './style/navbar.scss'
import Logo from '../../assets/images/Group 9408.svg'
import Menu from '../../assets/images/menu.svg'
import Buildings from '../../assets/images/buildings.svg'
// import Users from '../../assets/images/Group_9357.svg'
import Reports from '../../assets/images/report.svg'
import Help from '../../assets/images/doubt.svg'
import Notification from '../../assets/images/Group_9358.svg'
import Projects from '../../assets/images/Group_9357.svg'
import ProfileIcon from '../../assets/images/Icon feather-user.svg'
import Preferences from '../../assets/images/Icon feather-settings.svg'
import logOutIcon from '../../assets/images/Icon feather-log-out.svg'
import LastLogin from '../../assets/images/Icon feather-clock.svg'
import Arrow from '../../assets/images/Group Copy.svg'
import Image from '../Image'
import {
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
  COMPANY_ROLE,
  ROLE_KEYWORD,
  TOKEN_KEYWORD,
  USERNAME_KEYWORD,
} from '../../constants'

export const NavbarComponent = (): JSX.Element => {
  const history = useHistory()
  const newDate = new Date()
  const currDate = newDate.toLocaleString()
  const [name, setName] = useState(readCookie(USERNAME_KEYWORD))
  const logOut = (): void => {
    logout()
      .then((res: any) => {
        history.push('/login')
      })
      .finally(() => {
        eraseCookie(TOKEN_KEYWORD)
        history.push('/login')
      })
  }

  // eslint-disable-next-line consistent-return
  const makeShortname = (): any => {
    if (name) {
      const fullName = name.split(' ')
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0)
      return initials.toUpperCase()
    }
  }
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="justify-content-lg-end fixed-top"
      >
        <Container>
          {/* <Navbar.Brand href="#home"> */}
          <img src={Logo} alt="" className="img-fluid logo px-3" />
          {/* </Navbar.Brand> */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
              <Link to="/dashboard" className="nav-item nav-link-item">
                <Image src={Menu} alt="Dashboard" />
                <span className="text-center ms-1 mb-0 nav-link-text">
                  Dashboard
                </span>
              </Link>
            )}
            {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
              readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
              <>
                <Link to="/company" className="nav-item nav-link-item">
                  <Image src={Buildings} alt="Company" />
                  <span className="text-center ms-1 mb-0 nav-link-text">
                    Company
                  </span>
                </Link>
              </>
            )}
            <div className="nav-item nav-link-item">
              <span className="me-2">
                <Image src={Projects} alt="My Projects" />
              </span>
              <NavDropdown
                id="myproject"
                title={
                  <span>
                    My Projects
                    <img
                      src={Arrow}
                      alt="dropdown"
                      className="dropdown_arrow ms-2"
                    />
                  </span>
                }
                className="w-100 project-label project-dropdown align-self-center"
              >
                <NavDropdown.Item>
                  <Link to="/my-project" className="dropdown-item ps-0">
                    My Projects
                  </Link>
                </NavDropdown.Item>
                {/* {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
                  readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && ( )} */}
                {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
                  <>
                    <NavDropdown.Item>
                      <Link to="/template" className="dropdown-item ps-0">
                        Templates
                      </Link>
                    </NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
            </div>
            {/* <Link to="/my-reports" className="nav-item nav-link-item">
              <Image src={Reports} alt="Reports" />
              <span className="text-center ms-1 mb-0 nav-link-text">
                My Reports
              </span>
            </Link> */}
            {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
              <>
                <Link to="/my-reports" className="nav-item nav-link-item">
                  <Image src={Reports} alt="Reports" />
                  <span className="text-center ms-1 mb-0 nav-link-text">
                    My Reports
                  </span>
                </Link>
              </>
            )}
            <Link to="/help" className="nav-item nav-link-item px-3 py-2">
              <Image src={Help} alt="Dashboard" />
              <img src="assets/doubt.svg" alt="" />
              <span className="text-center ms-1 me-4 mb-0 nav-link-text">
                Help
              </span>
            </Link>
            <div className="vl" />
            {/* </Nav.Link> */}
          </Navbar.Collapse>

          {/* <Nav.Link className="nav-item nav-item-notification">
            <Image src={Notification} alt="Notification" />
            <div className="noti-num-container">
              <span className="noti-num">1</span>
            </div>
          </Nav.Link> */}
          {/* <Nav.Link href="#pricing" className="nav-item nav-item-profile"> */}
          {/* <div className="profile">
              <span>IO</span>
            </div> */}
          {/* <p id="user_name">Irena Oswald</p> */}
          <NavDropdown
            title={
              <div className="d-flex">
                <div className="profile">
                  <span>{makeShortname()}</span>
                </div>
                <span id="user_name" className="ms-2 flex-grow-1">
                  {name ? name.split(' ')[0] : 'John Doe'}
                  <br />
                  <span className="fw-light fs-6 text-capitalize">
                    {readCookie('role')}
                  </span>
                </span>
                <img
                  src={Arrow}
                  alt="dropdown"
                  className="dropdown_arrow ms-2"
                />
              </div>
            }
            id="collasible-nav-dropdown"
            className="align-self-center profile-dropdown"
          >
            <NavDropdown.Item>
              <div className="nav-dropdown-wrapper">
                <img
                  src={ProfileIcon}
                  alt="Profile Icon"
                  className="nav-dropdown-icon"
                />
                <span className="nav-dropdown-text">
                  <Link to="/settings">My Profile</Link>
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="nav-dropdown-wrapper">
                <img
                  src={Preferences}
                  alt="Preferences"
                  className="nav-dropdown-icon"
                />
                <span className="nav-dropdown-text">
                  <Link to="/settings">Preferences</Link>
                </span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div className="nav-dropdown-wrapper">
                <img
                  src={LastLogin}
                  alt="Last Login Icon"
                  className="nav-dropdown-icon"
                />
                <div className="last-login-wrapper">
                  <span className="nav-dropdown-text">Last Login</span>
                  <span className="nav-dropdown-date">{currDate}</span>
                </div>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={logOut}>
              <div className="nav-dropdown-wrapper">
                <img
                  src={logOutIcon}
                  alt="LogOut"
                  className="nav-dropdown-icon"
                />
                <span className="nav-dropdown-text">Logout</span>
              </div>
            </NavDropdown.Item>
          </NavDropdown>
          {/* </Nav.Link> */}
        </Container>
      </Navbar>
    </>
  )
}

/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
// import '../styles/chart.css'
import { useState, useEffect } from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { Select } from 'antd'
import { useParams } from 'react-router-dom'
import {
  getRatersSurveyStatusApi,
  getAllRaters,
} from '../../../../../../api/API'

const { Option } = Select
interface Props {
  employeeId: any
  details: any
}

const RaterChart: React.FC<Props> = ({ employeeId, details }): JSX.Element => {
  const params = useParams<any>()
  const { id } = params // project id

  const [empId, setEmpId] = useState(employeeId)
  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(0)
  const [raterlist, setRaterList] = useState<any>([])
  const [proejctId, setProejctId] = useState('')
  // on page load pass participant id static all and get chart data

  // function call on change and get chart data
  const getRatersChartData = (ratersId: any, projectId: any): any => {
    const apidata = {
      employee_id: employeeId,
      project_id: projectId,
      rater_id: ratersId,
    }
    console.log('apidata', apidata)
    getRatersSurveyStatusApi(apidata).then((res: any) => {
      setCompleteSurvey(res.data.data.completedSurveyStatus)
      setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
    })
  }

  const getRatersCategory = (projectID: any): void => {
    console.log('projectID', projectID)
    if (projectID) {
      setProejctId(projectID)
      getAllRaters(projectID).then((res: any) => {
        setRaterList(res.data.data)
        getRatersChartData('all', projectID)
      })
    }
  }
  const data = [
    {
      name: 'Not Completed Survey',
      survey: 100 - completeSurvey,
      color: '#FED68B',
    },
    {
      name: 'Completed Survey',
      survey: completeSurvey === null ? setCompleteSurvey(0) : completeSurvey,
      color: '#FCB737',
    },
  ]
  // const data = [
  //   { name: 'Not Completed Survey', survey: 65, color: '#FED68B' },
  //   { name: 'Completed Survey', survey: 35, color: '#FCB737' },
  // ]
  return (
    <div>
      <h5>Raters Participation</h5>
      <div className="position-relative mt-1">
        <Select
          defaultValue=""
          style={{ width: '100%' }}
          onChange={(e) => getRatersCategory(e)}
        >
          <Option value="">Select Project</Option>
          {details.project_id.map((project: any, index: any) => (
            <Option key={project._id} value={project._id}>
              {project.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="position-relative mt-4">
        <span className="floating-label-rater font-smaller text-muted">
          Select Raters
        </span>
        <Select
          defaultValue="all"
          style={{ width: '100%' }}
          onChange={(e) => getRatersChartData(e, proejctId)}
        >
          <Option value="all">All</Option>
          {raterlist.map((user: any, index: any) =>
            // eslint-disable-next-line eqeqeq
            user.title != 'Self' ? (
              <Option key={user._id} value={user._id}>
                {user.title}
              </Option>
            ) : null,
          )}
        </Select>
      </div>
      <div className="statsbody d-flex justify-content-around mt-0">
        <div className="pie">
          <PieChart width={250} height={250} className="">
            <Pie
              data={data}
              dataKey="survey"
              outerRadius={100}
              innerRadius={50}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <p className="percent-r">
            {completeSurvey ? (
              <>
                {completeSurvey === 100
                  ? completeSurvey
                  : completeSurvey.toPrecision(2)}
              </>
            ) : (
              <></>
            )}
            %
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-around">
        {data.map((entry, index) => (
          <div className="d-flex" key={entry.name}>
            <div
              className="dot1 me-3"
              style={{ backgroundColor: entry.color }}
            />
            <p>{entry.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RaterChart

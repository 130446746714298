/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Row, Col, Checkbox } from 'antd'
import { Doughnut, defaults } from 'react-chartjs-2'
import {
  MoreOutlined,
  UserOutlined,
  ContainerOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import './styles/projectCard.scss'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import userCountImg from '../../../../assets/images/Icon feather-users.svg'
import taskIcon from '../../../../assets/images/Icon awesome-tasks.png'
import { readCookie } from '../../../../api/CookieScript'

import {
  COMPANY_ROLE,
  EMPLOYEE_ROLE,
  ROLE_KEYWORD,
  SUPER_ADMIN_ROLE,
} from '../../../../constants/index'

interface Props {
  newdata: any
  deleteProject: any
  changeMode: any
}

export const ProjectCard: React.FC<Props> = ({
  newdata,
  deleteProject,
  changeMode,
}) => {
  const chartFn = (completed: any, notCompleted: any): any => {
    return {
      datasets: [
        {
          data: [completed || 0, 100 - completed],
          backgroundColor: ['#008174', '#b2d9d5'],
          hoverOffset: 0,
        },
      ],
    }
  }

  const chartPlugins = (completed: any): any => {
    const plugins = [
      {
        beforeDraw(chart: any) {
          const { width } = chart
          const { height } = chart
          const { ctx } = chart
          ctx.restore()
          const fontSize = (height / 100).toFixed(2)
          ctx.font = `${fontSize}em sans-serif`
          ctx.textBaseline = 'middle'
          const text = `${completed}%`
          const textX = Math.round((width - ctx.measureText(text).width) / 2)
          const textY = height / 1.6
          ctx.fillText(text, textX, textY)
          ctx.save()
        },
      },
    ]
    return plugins
  }

  return (
    <div
      className={
        newdata.mode
          ? 'activate-card-bg project_card'
          : 'deactivate-card-bg project_card'
      }
    >
      <div className="card-div1 justify-content-end">
        {/* <input type="checkbox" className="checkbox" /> */}
        <div className="dots-wrapper">
          {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
            <>
              <DropdownButton
                title={<MoreOutlined />}
                className="more-outlined ms-3 setting-dropdown"
              >
                <Link
                  to={{ pathname: `/project-detail/${newdata._id}` }}
                  className="dropdown-item"
                >
                  <span className="pe-2">
                    <EyeOutlined />
                  </span>
                  View
                </Link>

                <Link
                  to={{ pathname: `/project-detail/${newdata._id}` }}
                  className="dropdown-item"
                >
                  <span className="pe-2">
                    <EditOutlined />
                  </span>
                  Edit
                </Link>
                <Dropdown.Item onClick={() => deleteProject(newdata._id)}>
                  <span className="pe-2">
                    <DeleteOutlined />
                  </span>
                  Delete
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => changeMode(newdata._id, !newdata.mode)}
                >
                  <span
                    className={newdata.mode ? 'deactivate-dot' : 'activate-dot'}
                  />
                  {newdata.mode ? 'Deactivate' : 'Active'}
                </Dropdown.Item>
              </DropdownButton>
            </>
          )}

          {/* <DropdownButton
            title={<MoreOutlined />}
            className="more-outlined ms-3 setting-dropdown"
          >
            <Link
              to={{ pathname: `/project-detail/${newdata._id}` }}
              className="dropdown-item"
            >
              <span className="pe-2">
                <EyeOutlined />
              </span>
              View
            </Link>
           
            <Link
              to={{ pathname: `/project-detail/${newdata._id}` }}
              className="dropdown-item"
            >
              <span className="pe-2">
                <EditOutlined />
              </span>
              Edit
            </Link>
            <Dropdown.Item onClick={() => deleteProject(newdata._id)}>
              <span className="pe-2">
                <DeleteOutlined />
              </span>
              Delete
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => changeMode(newdata._id, !newdata.mode)}
            >
              <span
                className={newdata.mode ? 'deactivate-dot' : 'activate-dot'}
              />
              {newdata.mode ? 'Deactivate' : 'Active'}
            </Dropdown.Item>
          </DropdownButton> */}
        </div>
      </div>
      <div className="card-div2">
        <div className="card1-chart-wrapper">
          {/* <div className="label-wrapper">
            <span>{Math.round(newdata.completedSurveyCount) || 0}</span>
          </div> */}
          <div className="chart-wrapper">
            <Doughnut
              data={chartFn(
                newdata.completedSurveyCount,
                100 - newdata.completedSurveyCount,
              )}
              plugins={chartPlugins(newdata.completedSurveyCount)}
            />
          </div>
        </div>
        <div className="card-project-detail">
          <Link to={{ pathname: `/project-detail/${newdata._id}` }}>
            <span
              className="project-name"
              style={{ textDecoration: newdata.mode ? 'none' : 'line-through' }}
            >
              {newdata.name}
            </span>
          </Link>
          <div className="project-id-wrapper">
            <div>
              <span className="project-id">
                {/* <ContainerOutlined /> */}
                <img src={taskIcon} alt="task" />
                &nbsp;&nbsp;{newdata.projectID}
              </span>
            </div>
            <div className="project-documentid-wrapper">
              <span className="project-documentid">
                <img src={userCountImg} alt="user" className="pe-1" />
                {newdata.employees ? newdata.employees.length : '0'}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-div3">
        <div>
          {newdata.mode && (
            <div
              className="live-status-dot"
              style={{ background: '#2EEC74' }}
            />
          )}
          {!newdata.mode && (
            <div
              className="live-status-dot"
              style={{ background: '#808080' }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
import { Table, Button, Modal } from 'antd'
import { useState } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Doughnut, defaults } from 'react-chartjs-2'
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import MoreImg from '../../../../../assets/images/more.svg'
import PopupConfirm from '../../../../../assets/images/Group 9366.svg'
import { readCookie } from '../../../../../api/CookieScript'
import {
  COMPANY_ROLE,
  EMPLOYEE_ROLE,
  ROLE_KEYWORD,
  SUPER_ADMIN_ROLE,
} from '../../../../../constants/index'

interface Props {
  projectList?: any
  deleteProject?: any
}

export const ProjectListView: React.FC<Props> = ({
  projectList,
  deleteProject,
}): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [ProjectIdForDelete, setProjectIdForDelete] = useState('')

  const chartFn = (completed: any, notCompleted: any): any => {
    return {
      datasets: [
        {
          data: [completed || 0, 100 - completed],
          backgroundColor: ['#008174', '#b2d9d5'],
          hoverOffset: 0,
        },
      ],
    }
  }

  const columns = [
    // {
    //   title: 'id',
    //   dataIndex: '_id',
    // },
    {
      title: '',
      dataIndex: ['name'],
      render: (text: any, data: any) => (
        <div
          className={data.mode ? 'list-active-circle' : 'list-deactive-circle'}
        />
      ),
    },
    {
      title: 'PROJECT NAME',
      dataIndex: ['name', '_id'],
      render: (text: any, data: any) => (
        <>
          <Link to={{ pathname: `/project-detail/${data._id}` }}>
            <span
              style={{
                textDecoration: data.mode ? 'none' : 'line-through',
              }}
            >
              {data.name}
            </span>
          </Link>
        </>
      ),
    },
    {
      title: 'PROJECT ID',
      dataIndex: 'projectID',
      // render: (text: any) => <p className="mb-0">PR00001</p>,
    },
    // {
    //   title: 'REGION & LANGUAGE',
    //   dataIndex: 'sent_date',
    //   render: (text: any) => <p className="mb-0">US English</p>,
    // },
    // {
    //   title: 'REGISTERED BY',
    //   // dataIndex: 'to',
    //   render: (text: any) => <p className="mb-0">HR</p>,
    // },
    {
      title: 'REGISTERED DATE',
      dataIndex: 'date_added',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
    },
    {
      title: 'PARTICIPANT',
      // dataIndex: 'to',
      render: (text: any) => (
        <p className="mb-0">
          {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
            readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
            <>
              <span>{text.totalEmployee + text.totalParticipant}</span>
            </>
          )}
          {readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE && (
            <>
              <span>{text.participant.length}</span>
            </>
          )}
        </p>
      ),
    },
    {
      title: 'SURVEY',
      dataIndex: ['completedSurveyStatus', 'notCompletedSurveyStatus'],
      render: (text: any, data: any) => {
        return (
          <div
            style={{ height: '60px', width: '60px', textAlign: 'center' }}
            className="position-relative"
          >
            <span
              className="position-absolute"
              style={{ top: '46%', left: '0', right: '0', fontSize: '9px' }}
            >
              {/* {Math.round(data.completedSurveyCount)}% */}
              {Math.round(data.completedSurveyCount)}%
            </span>
            <Doughnut
              data={chartFn(
                data.completedSurveyCount,
                data.notCompletedSurveyCount,
              )}
            />
          </div>
        )
      },
    },
    {
      title: 'CONTACT PERSON',
      dataIndex: 'contact_person',
    },
    {
      title: '',
      dataIndex: 'sent_by',
      render: (text: any, record: any) => (
        <div className="d-flex">
          {/* {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
            readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && ( */}
          {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
            <>
              <p className="m-0 p-0" style={{ alignSelf: 'center' }}>
                {text}
              </p>
              <DropdownButton
                title={
                  <img src={MoreImg} alt="menu" className="projectMoreImg" />
                }
                className="more-outlined ms-3"
              >
                <Link
                  to={{ pathname: `/project-detail/${record._id}` }}
                  className="dropdown-item"
                >
                  <span className="pe-2">
                    <EyeOutlined />
                  </span>
                  View
                </Link>
                <Link
                  to={{ pathname: `/project-detail/${record._id}` }}
                  className="dropdown-item"
                >
                  <span className="pe-2">
                    <EditOutlined />
                  </span>
                  Edit
                </Link>
                <Dropdown.Item
                  onClick={() => {
                    setProjectIdForDelete(record._id)
                    setIsModalVisible(true)
                  }}
                >
                  <span className="pe-2">
                    <DeleteOutlined />
                  </span>
                  Delete
                </Dropdown.Item>
              </DropdownButton>
            </>
          )}
        </div>
      ),
    },
  ]

  return (
    <>
      <Table
        // scroll={{ x: true }}
        columns={columns}
        dataSource={projectList}
        pagination={false}
        rowKey="id"
        className="mt-3 h-100"
      />
      <Modal
        visible={isModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">
            Are you sure you want to remove Project ?
          </p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => {
                setIsModalVisible(false)
                deleteProject(ProjectIdForDelete)
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  Drawer,
  Button,
  Table,
  DatePicker,
  Checkbox,
  Select,
  Input,
  Image,
  Row,
  Col,
  Modal,
} from 'antd'
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  deleteTampateApi,
  filterTemplateApi,
  getTemplateTableDataApi,
} from '../../api/API'
import Nav from '../../components/Navbar'
import './style/myProject.scss'
import Menu from '../../assets/images/dots.svg'
import Filter from '../../assets/images/filter.svg'
import PopupConfirm from '../../assets/images/Group 9366.svg'
import ResetImg from '../../assets/images/reset.svg'
import more from '../../assets/images/more.svg'
import GoArrow from '../../assets/images/goArrow.svg'
import { AddProject } from './components/addproject'
import DrawerFooter from '../../components/Drawer/footer/drawerFooter'
import rightArrow from '../../assets/images/rightArrow.svg'
import rightDoubleArrow from '../../assets/images/right_double_arrow.svg'
import leftArrow from '../../assets/images/leftArrow.svg'
import leftDoubleArrow from '../../assets/images/left_double_arrow.svg'
import { readCookie } from '../../api/CookieScript'
import { SUPER_ADMIN_ROLE, ROLE_KEYWORD } from '../../constants'

const { Option } = Select

const list: any[] = [
  {
    templateName: 'templateName',
    templateID: 'templateID',
    constructs: '2',
    questions: '4',
    focusArea: '7',
    raters: '5',
    createdOn: '29-09-21',
    updatedOn: '29-09-21',
  },
]

interface DProps {
  label?: string
  elements?: any[]
}
const DropDown: React.FC<DProps> = ({ label, elements }): JSX.Element => {
  const currElements = elements === undefined ? [] : elements
  return (
    <div className="DropDown-container">
      <DropdownButton id="dropdown-item-button" title="All">
        {currElements.map((ele, index) => (
          <Dropdown.Item as="button">{ele}</Dropdown.Item>
        ))}
      </DropdownButton>
      <span className="label">{label}</span>
    </div>
  )
}
DropDown.defaultProps = {
  label: 'dropdown',
  elements: [],
}

export const Templates: React.FC = (): JSX.Element => {
  const dateFormat = 'YYYY/MM/DD'
  const [isRemindVisible, setIsRemindVisible] = useState<boolean>(false)
  const [visible, setVisible] = useState(false)
  const [DeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [data, setData] = useState([])
  const [tempateId, setTempateId] = useState()
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [filterSearchBar, setFilterSearchBar] = useState('')
  const [filterStatus, setFilterStatus] = useState('')
  const [rerender, setRerender] = useState(Math.random())
  const [filterDate, setFilterDate] = useState('')

  const [customPage, setCustomPage] = useState<any>(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [drawerVisible, setDrawerVisible] = useState(false)
  const onClose = function (): void {
    setDrawerVisible(false)
  }

  const getTempateTableData = (): void => {
    const page = {
      page_no: currentPage,
    }
    getTemplateTableDataApi(page).then((res: any) => {
      setData(res.data.data.data)
      setTotalPages(res.data.data.totalPages)
    })
  }

  useEffect(() => {
    // get the list of templates
    getTempateTableData()
  }, [currentPage])

  const showDrawer = (): any => {
    setVisible(true)
    setIsRemindVisible(true)
  }

  const columns = [
    // {
    //   dataIndex: 'active',
    //   key: 'active',
    //   width: '1%',
    //   render: () => {
    //     return <div className="project-active-dot"> </div>
    //   },
    // },
    {
      title: 'TEMPLATE NAME',
      dataIndex: 'template_name',
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: 'TEMPLATE ID',
      dataIndex: 'template_id',
    },
    {
      title: 'Constructs',
      dataIndex: 'construct',
    },
    {
      title: 'Questions',
      dataIndex: 'QuestionsCount',
    },
    {
      title: 'FOCUS AREA',
      dataIndex: 'focusarea',
    },
    {
      title: 'RATERS',
      dataIndex: 'raterscategory',
    },
    {
      title: 'CREATED ON',
      dataIndex: 'created_at',
    },
    {
      title: 'DELETE',
      dataIndex: 'delete',
      render: (text: any, record: any) => (
        <div className="">
          <DeleteOutlined
            onClick={() => {
              setDeleteModalVisible(true)
              // eslint-disable-next-line no-underscore-dangle
              setTempateId(record._id)
            }}
          />
        </div>
      ),
    },
    // {
    //   title: 'UPDATED ON',
    //   dataIndex: 'UPDATED_ON',
    // },
    // {
    //   render: (text: any) => (
    //     <a href="/project-detail">
    //       <Image src={more} width={20} preview={false} />
    //     </a>
    //   ),
    // },
  ]

  const deleteTempate = (id: any): void => {
    deleteTampateApi(id).then((res: any) => {
      getTempateTableData()
    })
  }

  const filterTempate = (key: string, value: any): void => {
    const existingQury = filterQuery
    if (key === 'name' && value.target.value !== '') {
      existingQury[key] = value.target.value
    }
    if (key === 'mode') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }

    if (key === 'date_added' && value !== null) {
      existingQury[key] = value
    }
    if (key === 'date_added' && value === null) {
      getTempateTableData()
    }

    if (key === 'name' && value.target.value === '') {
      getTempateTableData()
    }

    setFilterQuery(existingQury)

    filterTemplateApi(existingQury)
      .then((res: any): any => {
        setData(res.data.data.data)
      })
      .catch((error: any) => {
        if (error.response) {
          if (error.response.status === 401) {
            console.log(error)
          }
        }
      })
  }

  const handleNumberInput = (e: any): void => {
    const valueTemp = e.target.value
    if (/[0-9]/.test(valueTemp)) {
      setCustomPage(valueTemp <= totalPages ? valueTemp : 1)
    } else {
      setCustomPage('')
    }
  }

  return (
    <div className="project-section-container min-85vh">
      <div className="projects-header">
        <div className="project-header-title">
          <span className="project-title">TEMPLATES</span>
          <div className="project-header-links">
            {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
              <>
                <Link to="/dashboard">
                  <span className="project-link">Dashboard</span>
                </Link>
                <img
                  src={rightArrow}
                  alt="rightArrow"
                  className="project-rightArrow"
                />
                <Link to="/dashboard">
                  <span className="project-link">My Projects</span>
                </Link>
              </>
            )}
            <Link to="/dashboard">
              <span className="project-link">My Projects</span>
            </Link>
            <img
              src={rightArrow}
              alt="rightArrow"
              className="project-rightArrow"
            />
            <Link to="/dashboard">
              <span className="project-link">Templates</span>
            </Link>
          </div>
        </div>
        <div className="project-header-button">
          <Button
            onClick={() => setDrawerVisible(true)}
            className="project-button"
          >
            ADD New Project
          </Button>
        </div>
      </div>
      {/* .. */}
      <div className="projects-filterbar">
        <Row gutter={[16, 16]} className="report-filter-container">
          <Col
            xs={19}
            sm={20}
            md={12}
            lg={12}
            xl={9}
            className="position-relative"
          >
            <span className="floating-label-search font-smaller">Search</span>
            <Input
              key={rerender}
              defaultValue={filterSearchBar}
              placeholder="Search by Name, ID"
              onChange={(e: any) => {
                filterTempate('name', e)
              }}
            />
          </Col>
          {/* <Col
            xs={12}
            sm={24}
            md={5}
            lg={5}
            xl={3}
            className="position-relative desk-data"
          >
            <span className="floating-label-mode font-smaller">Status</span>
            <Select
              key={rerender}
              defaultValue={filterStatus}
              style={{ width: '100%' }}
              onSelect={(e: any) => filterTempate('mode', e)}
            >
              <Option value="">Select</Option>
              <Option value="active">Active</Option>
              <Option value="deactive">Deactive</Option>
            </Select>
          </Col> */}
          <Col
            xs={12}
            sm={24}
            md={7}
            lg={7}
            xl={3}
            className="position-relative border-right-dotted desk-data"
          >
            <span className="floating-label-date font-smaller">By Date</span>
            <DatePicker
              // defaultValue={filterDate}
              format={dateFormat}
              onChange={(date, dateString) => filterTempate('date_added', date)}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={3}
            lg={2}
            xl={2}
            className="position-relative desk-data"
          >
            <Button
              className="ms-2 border-radius-6px"
              style={{
                padding: '8px 6px',
                backgroundColor: '#F6F9FB',
              }}
              onClick={() => {
                setRerender(Math.random())
                setFilterSearchBar('')
                setFilterDate('')
                setFilterStatus('')
                getTempateTableData()
              }}
            >
              <img src={ResetImg} alt="reset" className="w-75" />
            </Button>
          </Col>
          {/* Desktop Data Part over */}
          {/* --------------------------- */}
          {/* Mobile Data Part Start */}
          <Col xs={3} sm={10} md={12} lg={8} className="report_filter_button">
            <Button id="report_filter_btn" onClick={showDrawer}>
              Filter
            </Button>
          </Col>
          <Drawer
            title="Filter"
            placement="right"
            // height={279}
            getContainer={false}
            onClose={() => setVisible(false)}
            visible={isRemindVisible}
            closable={false}
            extra={
              <CloseOutlined
                style={{ float: 'right' }}
                onClick={() => setIsRemindVisible(false)}
              />
            }
          >
            <Col
              xs={24}
              sm={24}
              md={6}
              className="position-relative border-right-dotted date-input mt-3"
            >
              <span className="floating-label-date font-smaller text-muted">
                By Date
              </span>
              <DatePicker
                format={dateFormat}
                style={{ width: '93%', borderRadius: '5px' }}
              />
            </Col>
            <Row justify="space-between" className="pt-3">
              {/* <Col
                xs={14}
                sm={12}
                md={6}
                lg={6}
                xl={3}
                className="position-relative ms-2"
              >
                <span className="floating-label-mode font-smaller text-muted">
                  Status
                </span>
                <Select defaultValue="active" style={{ width: '100%' }}>
                  <Option value="active">Active</Option>
                  <Option value="deactive">Deactive</Option>
                </Select>
              </Col> */}
              <Col xs={9} sm={2} md={12}>
                <Button
                  style={{
                    padding: '8px 6px',
                    backgroundColor: '#F6F9FB',
                  }}
                >
                  <img src={ResetImg} alt="reset" className="w-75" />
                </Button>
                {/* <Button
                  className="ms-2 mt-3"
                  style={{
                    padding: '8px 6px',
                    backgroundColor: '#F6F9FB',
                  }}
                >
                  <img src={MoreImg} alt="menu" className="w-75" />
                </Button> */}
              </Col>
              <span id="dash-border" />
              {/* <Col
                xs={24}
                sm={24}
                md={12}
                className="position-relative text-center"
              >
                <span className="advane_filter_btn">
                  <img
                    src={advanceFilter}
                    alt="advance filter"
                    className="adv_filter_img me-1"
                  />
                  Advance Filter
                </span>
              </Col> */}
              <Col
                xs={24}
                sm={24}
                md={12}
                className="position-relative text-center"
              >
                <Button
                  className="apply_btn float-end mt-3 me-2"
                  onClick={() => setIsRemindVisible(false)}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Drawer>
          {/*= ===Drawer Over==== */}
          <Col
            xs={24}
            sm={24}
            md={14}
            // lg={{ offset: 14, span: 10 }}
            lg={24}
            xl={8}
            xxl={readCookie('role') === SUPER_ADMIN_ROLE ? 7 : 7}
            className="ms-auto"
          >
            <div className="d-flex justify-content-end">
              <Button
                icon={<img src={leftArrow} className="w-25" alt="First" />}
                onClick={() => setCurrentPage(1)}
                className="rounded-start rounded-end-0"
              />
              <Button
                icon={
                  <img src={leftDoubleArrow} className="w-50" alt="Previous" />
                }
                onClick={() =>
                  setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
                }
                className="rounded-0"
              />
              <Button className="page-label rounded-0">
                Page {currentPage} of {totalPages}{' '}
              </Button>
              <Button
                icon={
                  <img src={rightDoubleArrow} className="w-50" alt="Last" />
                }
                onClick={() =>
                  setCurrentPage(currentPage < totalPages ? currentPage + 1 : 1)
                }
                className="rounded-0"
              />
              <Button
                icon={<img src={rightArrow} className="w-25" alt="Next" />}
                onClick={() => setCurrentPage(totalPages)}
                className="rounded-start-0 rounded-end"
              />
              <Input
                type="text"
                placeholder="Go to"
                className="goto-field ms-1"
                value={customPage}
                onChange={(e) => handleNumberInput(e)}
                // onChange={(e: any) =>
                //   setCustomPage(e.target.value <= totalPages ? e.target.value : 1)
                // }
              />
              <Button
                icon={<img src={GoArrow} alt="Go" className="w-50" />}
                className="btn-theme goto-btn ms-1"
                onClick={() => setCurrentPage(customPage)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <hr className="template-hr" />
      {/* .. */}
      <div className="project-table mt-1">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className="custom-ant-table"
        />
      </div>
      <div className="drawer-wrapper">
        <Drawer
          onClose={() => {
            setDrawerVisible(false)
          }}
          visible={drawerVisible}
          title="ADD NEW PROJECT"
          width={window.innerWidth > 550 ? 465 : '100%'}
          footer={<DrawerFooter />}
        >
          <AddProject
            setDrawerVisible={setDrawerVisible}
            cleardrawer={drawerVisible}
          />
        </Drawer>
      </div>

      {/* Delete Modal  */}
      <Modal
        visible={DeleteModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">Are you sure you want to Delete?</p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setDeleteModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => {
                setDeleteModalVisible(false)
                deleteTempate(tempateId)
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Select, Button, notification } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import UserIcon from '../../../../../../assets/images/Icon awesome-user-circle.svg'
import { updateEmployeeDetail } from '../../../../../../api/API'

interface Props {
  details: any
  employeeId: any
  isReadOnly: boolean
  setDrawerVisible: any
  setIsReadOnly: any
}

export const DetailTab: React.FC<Props> = ({
  details,
  employeeId,
  isReadOnly,
  setDrawerVisible,
  setIsReadOnly,
}): JSX.Element => {
  // console.log(isReadOnly)
  const children = []
  const [logo, setlogo] = useState<any>()
  const [priviewImg, setPriviewImg] = useState<any>(
    details.image ? details.image : '',
  )
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  // Error State
  const [firstNameErr, setFirstNameErr] = useState('')
  const [lastNameErr, setLastNameErr] = useState('')
  const [rerenderState, setRerenderState] = useState(Math.random())

  // eslint-disable-next-line no-plusplus
  for (let i = 10; i < 36; i++) {
    children.push(
      // eslint-disable-next-line react/jsx-no-undef
      `<Option key=${i.toString(36) + i}>${i.toString(36) + i}</Option>`,
    )
  }

  useEffect(() => {
    // console.log(details)

    setPriviewImg(details.image)
    setFirstName(details.first_name)
    setLastName(details.last_name)
    setEmail(details.email)
    setPhone(details.phone)
    setTimeout(() => {
      setRerenderState(Math.random())
    }, 200)
  }, [details])

  const handleSubmit = (): void => {
    setFirstNameErr('')
    setLastNameErr('')

    // check Validation
    let valid = true

    if (firstName == '') {
      setFirstNameErr('Please Enter Valid First Name')
      valid = false
    }
    if (lastName == '') {
      setLastNameErr('Please Enter Valid Last Name')
      valid = false
    }
    if (valid) {
      const formData = new FormData()
      formData.append('image', logo)
      formData.append('first_name', firstName)
      formData.append('last_name', lastName)
      formData.append('email', email)
      formData.append('phone', phone)

      // eslint-disable-next-line no-underscore-dangle
      updateEmployeeDetail(formData, details._id).then((res: any) => {
        const result = res.data
        setIsReadOnly(true)
        setDrawerVisible(false)
        notification.success({
          message: result.message,
          description: '',
        })
      })
    }
  }

  const imagePreview = (e: any): void => {
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setPriviewImg(objectUrl)
    setlogo(e.target.files[0])
  }

  return (
    <Form
      className="mt-2 pt-0 user-list-container-popup"
      onFinish={handleSubmit}
      key={rerenderState + employeeId}
    >
      <div className="d-flex justify-content-around align-items-center">
        <div className="position-relative">
          <p className="text-center">My Profile</p>
          <label htmlFor="myfile">
            <img
              style={{ cursor: 'pointer' }}
              className="profile-pic"
              src={priviewImg || UserIcon}
              alt="profile-pic"
            />
          </label>
          {!isReadOnly && (
            <label className="filebutton">
              <CameraOutlined />
              <span>
                <input
                  type="file"
                  id="myfile"
                  readOnly={isReadOnly}
                  onChange={(e) => imagePreview(e)}
                />
              </span>
            </label>
          )}
        </div>
        <span className="w-50 p-2 profile-pic-rule">
          <p className="bg-EDECEC p-2 profile-pic-rule">
            please make sure the profile pic file must be less then 1mb and in a
            dimentation of 150 x 150
          </p>
        </span>
      </div>
      <Row justify="space-between" className="mt-3" gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <label>
            First Name <span className="text-danger">*</span>
          </label>
          <Form.Item
          // initialValue={firstName}
          // rules={[{ required: true, message: 'Please Enter First Name' }]}
          >
            <Input
              placeholder="Please Enter First Name"
              className={`popup-sidebar-form-input ${
                !isReadOnly ? 'hilightborder' : ''
              } `}
              value={firstName}
              // defaultValue={firstName}
              // key={firstName}
              readOnly={isReadOnly}
              onChange={(e: any) => setFirstName(e.target.value)}
            />
            <label className="text-danger">{firstNameErr}</label>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <label>
            Last Name <span className="text-danger">*</span>
          </label>
          <Form.Item
          // rules={[{ required: true, message: 'Please Enter Last Name' }]}
          >
            <Input
              placeholder="Please Enter Last Name"
              className={`popup-sidebar-form-input ${
                !isReadOnly ? 'hilightborder' : ''
              } `}
              value={lastName}
              // defaultValue={lastName}
              // key={lastName}
              readOnly={isReadOnly}
              onChange={(e: any) => setLastName(e.target.value)}
            />
            <label className="text-danger">{lastNameErr}</label>
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <label>
            Permissions <span className="text-danger">*</span>
          </label>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={['a10', 'c12']}
            // className="popup-sidebar-form-input"
          >
            {children}
          </Select>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <label>
            Email
            {/* <span className="text-danger">*</span> */}
          </label>
          <Form.Item
            rules={[
              { required: false, type: 'email', message: 'Please Enter Email' },
            ]}
          >
            <Input
              placeholder="Please Enter Email"
              className={`popup-sidebar-form-input ${
                !isReadOnly ? 'hilightborder' : ''
              } `}
              value={email}
              key={email}
              readOnly
              // readOnly={isReadOnly}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
          // rules={[{ required: true, message: 'Please Enter Phone Number' }]}
          >
            Phone
            {/* <span className="text-danger">*</span> */}
            <Input
              placeholder="Please Enter Phone Number"
              className={`popup-sidebar-form-input ${
                !isReadOnly ? 'hilightborder' : ''
              } `}
              defaultValue={phone}
              // key={phone}
              readOnly={isReadOnly}
              onChange={(e: any) => setPhone(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      {!isReadOnly && (
        <div className="w-100 d-flex justify-content-end mt-4">
          <Button
            type="default"
            onClick={() => {
              setIsReadOnly(true)
              setDrawerVisible(false)
            }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="saveButton ms-3">
            Save
          </Button>
        </div>
      )}
      {/* <div className="bg-EDECEC p-2 m-3 user-popup-note">
        <b>Please Note: </b>
        <p>
          User will receive an invite email to join to the particular company
        </p>
      </div> */}
    </Form>
  )
}

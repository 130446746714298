/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import {
  Form,
  Row,
  Col,
  Button,
  Cascader,
  notification,
  Input,
  Checkbox,
} from 'antd'
import { useParams } from 'react-router-dom'

import {
  getListOfCascader,
  updateDistributionApi,
  getSingleDistributionApi,
  getAllRaters,
} from '../../../../../api/API'
import 'antd/dist/antd.css'

// const { SHOW_CHILD } = Cascader

// import { Form } from 'react-bootstrap'

// const Quill = ReactQuill.Quill
const Font = Quill.import('attributors/class/font')
const fontList = [
  'Calibri Regular',
  'Calibri Bold',
  'Calibri Italic',
  'Calibri Bold Italic',
  'Calibri Light',
  'Calibri Light Italic',
  'serif',
  'sans-serif',
  'monospace',
  'cursive',
  'fantasy',
  'system-ui',
  'ui-serif',
  'ui-sans-serif',
  'ui-monospace',
  'ui-rounded',
  'emoji',
  'math',
  'fangsong',
]
Font.whitelist = fontList.map((font) => getFontName(font))
Quill.register(Font, true)

let fontStyles = ''
fontList.forEach(function (font) {
  const fontName = getFontName(font)
  fontStyles +=
    `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before {` +
    `content: '${font}';` +
    `font-family: '${font}', sans-serif;` +
    `}` +
    `.ql-font-${fontName}{` +
    ` font-family: '${font}', sans-serif;` +
    `}`
})

function getFontName(font: any): any {
  return font.toLowerCase().replace(/\s/g, '-')
}

const node = document.createElement('style')
node.innerHTML = fontStyles
document.body.appendChild(node)

interface Props {
  // data: any
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  // isRemindVisible: React.Dispatch<React.SetStateAction<boolean>>
  distributionId: any
  isReadOnly: any
  isRandom: any
}

// export const EditProjectDetailFrom: React.FC = (): JSX.Element => {
export const EditProjectDetailFrom: React.FC<Props> = ({
  // data,
  setIsModalVisible,
  distributionId,
  isReadOnly,
  isRandom,
}) => {
  const params = useParams<any>()
  const { id } = params // project id

  const dateFormat = 'YYYY/MM/DD'
  const [raterlist, setRaterList] = useState<any>([])
  const [selfRaterID, setSelfRaterID] = useState('')
  // const [to, setTo] = useState([])
  const [distributionData, setDistributionData] = useState({})
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [directMailSending, setDirectMailSending] = useState<boolean>(false)
  const [fromName, setFromName] = useState('')
  const [fromEmail, setFromEmail] = useState('test@gmail.com')
  const [replyToEmail, setReplyToEmail] = useState('test@gmail.com')
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')
  // const [formData, setFormData] = useState({})
  const [cascader, setCascader] = useState([{}])
  const [originalAPIdata, setOriginalAPIdata] = useState<any>([])
  const [selectedEmpId, setSelectedEmpId] = useState<any>([])
  const [selectedParticipantId, setSelectedParticipantId] = useState<any>([])
  // const [employeeId, setEmployeeId] = useState([])
  // const [participantId, setParticipantId] = useState<any>([])
  // const [partiPlusEmp, setPartiPlusEmp] = useState<any>([])
  const [tempArray, setTempArray] = useState<any>([])

  const [selectedUSersFromDB, setSelectedUSersFromDB] = useState<any>([])

  // Error Stats
  const [cascaderErr, setCascaderErr] = useState('')
  const [dateErr, setDateErr] = useState('')
  const [timeErr, setTimeErr] = useState('')
  const [fromEmailErr, setFromEmailErr] = useState('')
  const [fromNameErr, setFromNameErr] = useState('')
  const [replyToEmailErr, setReplyToEmailErr] = useState('')
  const [subjectErr, setSubjectErr] = useState('')
  const [rerenderState, setRerenderState] = useState(Math.random())

  const todayData = new Date()
  const hr = todayData.getHours()
  const min =
    todayData.getMinutes() < 10
      ? `0${todayData.getMinutes()}`
      : todayData.getMinutes()
  const defaultTime = `${hr}:${min}`
  const defaultDate = todayData.toLocaleDateString('en-CA')

  // useEffect(() => {
  //  // getDistributionData()
  // })

  // useEffect(() => {
  //     setCascaderErr('')
  //     setDateErr('')
  //     setTimeErr('')
  //     setFromNameErr('')
  //     setSubjectErr('')
  //     setFromEmailErr('')
  //     setReplyToEmailErr('')
  //   getDistributionData()

  // }, [isReadOnly])

  useEffect(() => {
    getDistributionData()
  }, [isRandom])

  useEffect(() => {
    if (directMailSending) {
      setTime(defaultTime)
      setDate(defaultDate)
    }
  }, [directMailSending])

  useEffect(() => {
    getDistributionData()
  }, [distributionId])

  // const fetchAllRaters = (): void => {
  //   getAllRaters(id).then((res: any) => {
  //     setRaterList(res.data.data)
  //     const result = res.data.data
  //     const selfData = result.find(
  //       (rater: any) => rater.title.toLowerCase() === 'self',
  //     )
  //     setSelfRaterID(selfData._id)
  //   })
  // }

  // fill api values in cascader
  const getCascaderData = (listEmp: any): any => {
    getListOfCascader(id).then(async (res: any): Promise<any> => {
      setOriginalAPIdata(res.data.data)
      // eslint-disable-next-line no-console
      // console.log(res.data.data)
      const casdata = res.data.data
      const dataMap = []
      for (let i = 0; i < casdata.length; i++) {
        const subdata = []
        for (let j = 0; j < casdata[i].raters.length; j++) {
          // if (casdata[i].raters[j].title != 'Self') {
          const patlist = []
          for (let k = 0; k < casdata[i].raters[j].participant.length; k++) {
            patlist.push({
              code: casdata[i].raters[j].participant[k]._id,
              name: casdata[i].raters[j].participant[k].name,
            })
          }
          subdata.push({
            code: casdata[i].raters[j]._id,
            name: casdata[i].raters[j].title,
            items: patlist,
          })
          // }
        }
        dataMap.push({
          code: casdata[i]._id,
          name: casdata[i].first_name,
          items: subdata,
        })
      }
      setCascader(dataMap)
      let selfId
      // console.log('tempArray', listEmp)
      await getAllRaters(id).then((res1: any) => {
        setRaterList(res1.data.data)
        const result = res1.data.data
        const selfData = result.find(
          (rater: any) => rater.title.toLowerCase() === 'self',
        )
        selfId = selfData._id
        setSelfRaterID(selfData._id)
      })
      const empList = []
      for (let emp = 0; emp < casdata.length; emp++) {
        for (let rater = 0; rater < casdata[emp].raters.length; rater++) {
          // if (listEmp.includes(casdata[emp].raters[rater]._id.toString())) {
          //   empList.push([casdata[emp]._id, casdata[emp].raters[rater]._id])
          // }
          if (listEmp.includes(casdata[emp]._id.toString())) {
            // const selfData = raterlist.find(
            //   (r: any) => r.title.toLowerCase() === 'self',
            // )
            console.log(raterlist, selfId)
            if (selfId) {
              // empList.push([casdata[emp]._id, selfData._id])
              empList.push([casdata[emp]._id, selfId])
            }
          }
          const participantCount = casdata[emp].raters[rater].participant
          if (participantCount.length > 0) {
            for (let parti = 0; parti < participantCount.length; parti++) {
              // console.log('abc', participantCount[parti]._id)
              if (listEmp.includes(participantCount[parti]._id.toString())) {
                // console.log('dasda', participantCount[parti]._id)
                empList.push([
                  casdata[emp]._id,
                  casdata[emp].raters[rater]._id,
                  participantCount[parti]._id,
                ])
              }
            }
          }
        }

        // if (listEmp.includes(casdata[emp]._id.toString())) {
        //   // console.log('dasda', casdata[emp]._id)
        //   empList.push([casdata[emp]._id])
        // }
      }
      // console.log('empList', empList)

      setSelectedUSersFromDB(empList)

      setTimeout(() => {
        setRerenderState(Math.random())
      }, 100)
      // setPartiPlusEmp(empList)
      // setSelectedEmpId(empList)
    })
  }

  // get single distribution data
  const getDistributionData = (): any => {
    getSingleDistributionApi(distributionId).then((res: any) => {
      setDistributionData(res.data.data)
      setDate(res.data.data.date.substring(0, 10))
      setTime(res.data.data.time)
      setFromName(res.data.data.form_name)
      setFromEmail(res.data.data.from_email)
      setReplyToEmail(res.data.data.replay_to_email)
      setSubject(res.data.data.subject)
      setDescription(res.data.data.description)
      // cascader data store in state
      setSelectedEmpId(res.data.data.employee_id)
      setSelectedParticipantId(res.data.data.participant_id)
      setDirectMailSending(res.data.data.directMailSending || false)
      const empList = []

      for (let i = 0; i < res.data.data.employeeData.length; i++) {
        empList.push(res.data.data.employeeData[i]._id.toString())
      }

      // for (let i = 0; i < res.data.data.employee_id.length; i++) {
      //   empList.push(res.data.data.employee_id[i])
      // }
      for (let i = 0; i < res.data.data.participant_id.length; i++) {
        empList.push(res.data.data.participant_id[i].toString())
      }
      getCascaderData(empList)

      // setPartiPlusEmp(empList)
      setTempArray(empList)
    })
  }

  // cascader search filter
  const filter = (inputValue: any, path: any): any => {
    return path.some(
      (option: any) =>
        option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    )
  }
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  // handle Submit Method
  const handleSubmit = (): any => {
    console.log('call 222')
    console.log('selectedEmpId', selectedEmpId)
    console.log('date', date)
    console.log('selectedParticipantId', selectedParticipantId)
    console.log('time', time)
    console.log('fromName', fromName)
    console.log('subject', subject)
    console.log('fromEmail', fromEmail)
    console.log('replyToEmail', replyToEmail)

    setCascaderErr('')
    setDateErr('')
    setTimeErr('')
    setFromNameErr('')
    setSubjectErr('')
    setFromEmailErr('')
    setReplyToEmailErr('')
    // console.log(selectedEmpId)
    // console.log(selectedParticipantId)
    // check Validation
    // console.log('TEST')
    const totalLength = selectedEmpId.length + selectedParticipantId.length
    let valid = true

    if (totalLength == 0) {
      setCascaderErr('Please Select Employee,Participant')
      valid = false
    }

    // if (selectedEmpId.length == 0 && selectedParticipantId.length == 0) {
    //   setCascaderErr('Please Select Employee,Participant')
    //   valid = false
    // }
    if (date == '') {
      setDateErr('Please Select Date')
      valid = false
    }

    const today = new Date()
    const dd = today.getDate()
    const mm = today.getMonth() + 1 // January is 0!
    const yyyy = today.getFullYear()
    const newDate = `${yyyy}-${mm}-${dd}`

    if (new Date(date).getTime() < new Date(newDate).getTime()) {
      // setDateErr('Please Select Current or Future Date')
      // valid = false
    }

    // console.log(new Date(date).getTime(), new Date(newDate).getTime())

    if (time == '') {
      setTimeErr('Please Select Time')
      valid = false
    }
    if (fromName == '') {
      setFromNameErr('Please Enter From Name')
      valid = false
    }
    if (subject == '') {
      setSubjectErr('Please Enter Valid Subject')
      valid = false
    }
    // if (fromEmail == '' || !fromEmail.match(emailRegex)) {
    //   setFromEmailErr('Please Enter Valid Email')
    //   valid = false
    // }
    // if (replyToEmail == '' || !replyToEmail.match(emailRegex)) {
    //   setReplyToEmailErr('Please Enter Valid Email')
    //   valid = false
    // }
    if (valid) {
      const apidata = {
        project_id: id,
        employee: selectedEmpId,
        participants: selectedParticipantId,
        directMailSending,
        date,
        time,
        form_name: fromName,
        from_email: fromEmail,
        replay_to_email: replyToEmail,
        subject,
        description,
      }
      console.log('apidata', apidata)
      // pass updated distribution data to api

      updateDistributionApi(apidata, distributionId).then((res: any): any => {
        if (res.status !== 200) {
          notification.error({
            message: 'something went wrong refresh pages',
          })
        } else {
          setSelectedParticipantId([])
          notification.success({
            message: 'Distribution Update successfully',
          })
          onDrawerClose()
        }
      }) // api function over
    } // if over
  }
  const modules = {
    toolbar: [
      // [{ header: '1' }, { header: '2' }],
      [{ font: Font.whitelist }],
      [{ header: [1, 2, 3, 4, 5, 6, 7] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  }
  const formats = [
    'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  const options = cascader
  // function to store selected employee and participation id
  const cascaderFun = (choosenoption: any): any => {
    const empIds = []
    const patIds = []
    for (let index = 0; index < choosenoption.length; index++) {
      const element = choosenoption[index]

      if (element.length > 1) {
        // set participation Ids in state
        // const participationid = element[1]
        // const participationid = element[2]
        // const participationid = element.length === 3 ? element[2] : element[1]
        // if (patIds.indexOf(participationid) === -1) {
        //   patIds.push(participationid)
        // }

        if (element.length === 3) {
          const participationid = element[2]
          if (patIds.indexOf(participationid) === -1) {
            patIds.push(participationid)
          }
        }

        if (element.length === 2) {
          for (let index2 = 0; index2 < originalAPIdata.length; index2++) {
            if (originalAPIdata[index2]._id === element[0]) {
              for (
                let index3 = 0;
                index3 < originalAPIdata[index2].raters.length;
                index3++
              ) {
                for (
                  let index4 = 0;
                  index4 <
                  originalAPIdata[index2].raters[index3].participant.length;
                  index4++
                ) {
                  if (
                    element[1].toString() ===
                    originalAPIdata[index2].raters[index3]._id.toString()
                  ) {
                    patIds.push(
                      originalAPIdata[index2].raters[index3].participant[index4]
                        ._id,
                    )
                  }
                }
              }
            }
          }
        }
        // set employee Ids in state
        // const employeeid = element[0]
        // if (empIds.indexOf(employeeid) === -1) {
        //   empIds.push(employeeid)
        // }
        const employeeid = element[0]
        const selfid = element[1]
        if (selfRaterID === selfid) {
          if (empIds.indexOf(employeeid) === -1) {
            empIds.push(employeeid)
          }
        }
      } else {
        // set employee Ids in state
        const employeeid = element[0]
        if (empIds.indexOf(employeeid) === -1) {
          empIds.push(employeeid)
        }

        for (let index2 = 0; index2 < originalAPIdata.length; index2++) {
          if (
            originalAPIdata[index2]._id === employeeid &&
            originalAPIdata[index2].participant &&
            originalAPIdata[index2].participant.length > 0
          ) {
            for (
              let index3 = 0;
              index3 < originalAPIdata[index2].participant.length;
              index3++
            ) {
              patIds.push(originalAPIdata[index2].participant[index3]._id)
            }
          }
        }
      }
    }
    console.log('empIds', empIds)
    console.log('patIds', patIds)

    setSelectedEmpId(empIds)
    setSelectedParticipantId(patIds)
  }

  const disablePastDate = (): any => {
    const dtToday = new Date()
    let month: any = dtToday.getMonth() + 1
    let day: any = dtToday.getDate()
    const year: any = dtToday.getFullYear()

    if (month < 10) month = `0${month.toString()}`
    if (day < 10) day = `0${day.toString()}`
    return `${year}-${month}-${day}`
  }

  const onDrawerClose = (): void => {
    setRerenderState(Math.random())
    setCascaderErr('')
    setDate(defaultDate)
    setTime(defaultTime)
    // setFromEmail('')
    // setReplyToEmail('')

    // setDirectMailSending(directMailSending)
    // setFromName('')
    // setSubject('')
    // setSelectedUSersFromDB([])
    // setDescription('')
    // setSelectedEmpId([])
    // setSelectedParticipantId([])

    setIsModalVisible(false)
  }

  return (
    <div className="bg-gray-color project-form p-3">
      <b>Distribution Details</b>
      <p>Please fill in the below details for launching the project 5</p>
      <Form className="mt-4" key={rerenderState}>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={24} lg={24} xl={24}>
            <Form.Item className="cascaderStyle">
              <label>
                Email To
                <sup className="text-danger">*</sup>
              </label>
              <Cascader
                key="formControlCas"
                fieldNames={{ label: 'name', value: 'code', children: 'items' }}
                options={options}
                onChange={cascaderFun}
                // onChange={cascaderFun}
                defaultValue={selectedUSersFromDB}
                // showCheckedStrategy={SHOW_CHILD}
                multiple
                placeholder="Please select"
                className="d-block"
                showSearch={{ filter }}
                // onSearch={(name) => console.log(name)}
                disabled={isReadOnly}
              />
              <label className="text-danger">{cascaderErr}</label>
            </Form.Item>
          </Col>

          <Col xs={24} md={24} lg={24} xl={24}>
            <Form.Item>
              <Checkbox
                onChange={(e) => setDirectMailSending(e.target.checked)}
                checked={directMailSending}
                defaultChecked={directMailSending}
                disabled={isReadOnly}
              >
                <b>Send Now</b>
              </Checkbox>
              {/* <label>
                Direct Mail Sending                
              </label> */}
              {/* <Input
                key="formControlDate"
                checked={directMailSending}
                type="checkbox"
                onChange={(e) => setDirectMailSending(e.target.checked)}
              />  
              <b> Direct Mail Sending</b> */}
            </Form.Item>
          </Col>

          <Col xs={24} md={10} lg={10} xl={12}>
            <Form.Item className="mb-3">
              <label>
                Date
                <sup className="text-danger">*</sup>
              </label>
              <Input
                key="formControlDate"
                defaultValue={date}
                type="date"
                className="inputBorder"
                onChange={(e) => setDate(e.target.value)}
                disabled={directMailSending}
                value={date}
                min={disablePastDate()}
              />
              <label className="text-danger">{dateErr}</label>
            </Form.Item>
          </Col>
          <Col xs={24} md={10} lg={10} xl={12}>
            <Form.Item className="mb-3">
              <label>
                Time
                <sup className="text-danger">*</sup>
              </label>
              <Input
                key="formControlTime"
                type="time"
                className="inputBorder"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                disabled={directMailSending}
                // readOnly={isReadOnly}
              />
              <label className="text-danger">{timeErr}</label>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {/* <Col xs={24} md={10} lg={10} xl={12}>
            <Form.Item className="mb-3">
              <label>
                From
                <sup className="text-danger">*</sup>
              </label>
              <Input
                key="formControlEmil"
                type="email"
                placeholder="Email"
                className="inputBorder"
                defaultValue={fromEmail}
                onChange={(e) => setFromEmail(e.target.value)}
                readOnly={isReadOnly}
              />
              <label className="text-danger">{fromEmailErr}</label>
            </Form.Item>
          </Col> */}
          <Col xs={24} md={24} lg={24} xl={24}>
            <Form.Item className="mb-3">
              <label>
                From Name
                <sup className="text-danger">*</sup>
              </label>
              <Input
                key="formControlName"
                type="text"
                placeholder="Name"
                className="inputBorder"
                defaultValue={fromName}
                onChange={(e) => setFromName(e.target.value)}
                readOnly={isReadOnly}
              />
              <label className="text-danger">{fromNameErr}</label>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {/* <Col xs={24} md={24} lg={24}>
            <Form.Item className="mb-3 ">
              <label>
                Reply To
                <sup className="text-danger">*</sup>
              </label>
              <Input
                key="formControlReplayEmail"
                type="email"
                placeholder="Reply To"
                className="inputBorder"
                defaultValue={replyToEmail}
                onChange={(e) => setReplyToEmail(e.target.value)}
                readOnly={isReadOnly}
              />
              <label className="text-danger">{replyToEmailErr}</label>
            </Form.Item>
          </Col> */}
          <Col xs={24} md={24} lg={24}>
            <Form.Item className="mb-3 ">
              <label>
                Subject
                <sup className="text-danger">*</sup>
              </label>
              <Input
                key="formControlSubject"
                type="text"
                placeholder="Survey for new project"
                className="inputBorder"
                defaultValue={subject}
                onChange={(e) => setSubject(e.target.value)}
                readOnly={isReadOnly}
              />
              <label className="text-danger">{subjectErr}</label>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Item className="mb-3">
              <label>Description</label>
              {/* <Input as="textarea" rows={8} className="inputBorder" /> */}
              <ReactQuill
                theme="snow"
                formats={formats}
                modules={modules}
                className="inputBorder"
                defaultValue={description || ''}
                onChange={(e: any) => setDescription(e)}
                readOnly={isReadOnly}
              />
            </Form.Item>
            <h4>Shortcode</h4>
            <h5 className="text-break">
              &#36;&#123;first_name&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;last_name&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;l://SurveyURL&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;l://SurveyButton&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;employee_name&#125;
            </h5>
          </Col>
        </Row>
        {!isReadOnly && (
          <div className="text-end">
            <Button
              className="comman-button ghost mx-2 border-rounded"
              onClick={() => onDrawerClose()}
            >
              Cancel
            </Button>
            <Button
              className="comman-button btn-theme border-0"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
        )}
      </Form>
    </div>
  )
}

/* eslint-disable no-empty */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import ImgCrop from 'antd-img-crop'
import { notification, Form, Input, Button, Select } from 'antd'
// import Button from '../../../components/Button'
import './style/addCompany.scss'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import Camera from '../../../assets/images/camera.svg'
import AvatarImage from '../../../assets/images/Icon awesome-user-circle.svg'
import { createCompany } from '../../../api/API'

const { Option } = Select

interface Props {
  // eslint-disable-next-line react/require-default-props
  getCompanyList?: any
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddCompany: React.FC<Props> = ({
  getCompanyList,
  setDrawerVisible,
}) => {
  const [form] = Form.useForm()

  const [name, setname] = useState('')
  const [contact, setcontact] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [abn, setabn] = useState('')
  const [country, setcountry] = useState('')
  const [state, setstate] = useState('')
  const [add1, setadd1] = useState('')
  const [add2, setadd2] = useState('')
  const [pin, setpin] = useState('')
  // const [taxId, settaxId] = useState('')
  const [industry, setindustry] = useState('')
  const [employee, setemployee] = useState('')
  const [noofEmp, setNoofEmp] = useState('')
  const [file, setfile] = useState<any>()
  const [ImgErr, setImgErr] = useState<any>('')
  const [priviewImg, setPriviewImg] = useState<any>()
  const [clearDrawer, setClearDrawer] = useState(false)
  const [rerenderState, setRerenderState] = useState(Math.random())

  useEffect(() => {
    setname('')
    setcontact('')
    setemail('')
    setphone('')
    setabn('')
    setcountry('')
    setstate('')
    setadd1('')
    setadd2('')
    setpin('')
    setindustry('')
    setemployee('')
    setNoofEmp('')
    setfile('')
  }, [clearDrawer])
  // const setCountry = (e: any): void => {
  //   setcountry(e)
  // }
  const setState = (e: any): void => {
    setstate(e)
  }

  const setIndustry = (e: any): void => {
    setindustry(e)
  }
  const setEmployee = (e: any): void => {
    setemployee(e)
  }

  const handleSubmit = (): void => {
    if (file) {
      const formData = new FormData()
      formData.append('logo', file)
      formData.append('name', name)
      formData.append('contact_person', contact)
      formData.append('email', email)
      formData.append('phone', phone)
      formData.append('abn', abn)
      formData.append('country', country)
      formData.append('state', state)
      formData.append('address_line1', add1)
      formData.append('address_line2', add2)
      formData.append('zip_code', pin)
      // formData.append('taxation_id', taxId)
      formData.append('industry', industry)
      formData.append('employee', employee)
      formData.append('number_of_employee', noofEmp)

      createCompany(formData).then((res: any) => {
        if (res.isAxiosError && res.response.status === 400) {
          notification.error({
            type: 'error',
            message: res.response.data.message,
          })
        } else {
          if (res.data.code === 200) {
            notification.success({
              type: 'success',
              message: 'Company successfully Added',
            })
            setTimeout(() => {
              getCompanyList()
              setClearDrawer(true)
              setRerenderState(Math.random())
              setDrawerVisible(false)
            }, 1000)
          }
          if (res.data.code === 400) {
            notification.success({
              type: res.data.status,
              message: res.data.message,
            })
          }

          setRerenderState(Math.random())
          setname('')
          setcontact('')
          setfile('')
          setemail('')
          setphone('')
          setabn('')
          setcountry('')
          setstate('')
          setadd1('')
          setadd2('')
          setpin('')
          setindustry('')
          setemployee('')
          setNoofEmp('')
          setfile('')
        }
      })

      // window.location.reload()
    } else {
      setImgErr('Select Image')
    }
  }
  const imagePreview = (e: any): void => {
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setPriviewImg(objectUrl)
    setfile(e.target.files[0])
  }
  return (
    <div className="add-company">
      <div className="form-container">
        <Form onFinish={handleSubmit} key={rerenderState}>
          <div className="add-company-row1">
            <div className="profile-label pe-2">
              <span>Upload Photo</span>
            </div>
            {/* <div className="profile-photo">
              <div className="profile-circle">
                <img src={priviewImg ? priviewImg : AvatarImage} alt="dd" />
              </div>
              <div className="input-form">
                <Form.Item
                  name="file"
                  // rules={[{ required: true, message: 'Please Select Image' }]}
                  className="fileErr"
                >
                  <label htmlFor="profile-input cam-circle">
                    <img
                      src={Camera}
                      alt="Camera"
                      className="w-75"
                      style={{ marginLeft: '15%', marginTop: '25%' }}
                    />
                  </label>
                  <input
                    type="file"
                    name="image"
                    className="profile-input"
                    id="profile-input"
                    onChange={(e: any) => imagePreview(e)}
                    // accept=".svg"
                  />
                </Form.Item>
              </div>
            </div> */}
            <div className="profile-photo ms-3">
              <div className="profile-circle">
                <img src={priviewImg ? priviewImg : AvatarImage} alt="dd" />
              </div>
              <div className="input-form">
                <label htmlFor="profile-input">
                  <div className="cam-circle w-75">
                    <img src={Camera} alt="Camera" />
                  </div>
                </label>
                <Form.Item
                  name="logo"
                  // initialValue={logo}
                  // rules={[{ required: true, message: 'Select Image' }]}
                >
                  <Input
                    type="file"
                    className="profile-input"
                    id="profile-input"
                    onChange={(e: any) => imagePreview(e)}
                  />
                </Form.Item>
              </div>
            </div>
            {/* <span className="form-row"> */}
            {/* </span> */}
            <div className="profile-desc">
              <span>
                Please make sure the logo file must be less than 1mb and in a
                dimension of 150x150
              </span>
            </div>
          </div>
          <label className="text-danger ps-3">{ImgErr}</label>
          <div className="form-group">
            <label htmlFor="inputName4" className="form-label">
              Name
              <span className="asterik">*</span>
            </label>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Please Enter Company Name' }]}
            >
              <Input
                onChange={(e: any) => setname(e.target.value)}
                placeholder="Please Enter Company Name"
              />
            </Form.Item>
          </div>
          <div className="form-row">
            <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px' }}
            >
              <label htmlFor="inputContactPerson" className="form-label">
                Contact Person
                <span className="asterik">*</span>
              </label>
              <Form.Item
                name="contactname"
                rules={[
                  { required: true, message: 'Please Enter Contact Name' },
                ]}
              >
                <Input
                  onChange={(e: any) => setcontact(e.target.value)}
                  placeholder="Please Enter Contact Name"
                  key="contact"
                  value={contact}
                />
              </Form.Item>
            </div>
            <div
              className="form-group col col-md-6"
              style={{ paddingLeft: '7px' }}
            >
              <label htmlFor="inputEmail4" className="form-label">
                Email
                <span className="asterik">*</span>
              </label>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'The email is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please enter E-mail!',
                  },
                ]}
              >
                <Input
                  onChange={(e: any) => setemail(e.target.value)}
                  placeholder="Enter Email"
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px', width: '50%' }}
            >
              <label htmlFor="inputState" className="form-label">
                Employee
              </label>
              <Form.Item name="inputEmpoyee">
                <Select
                  onChange={setEmployee}
                  allowClear
                  placeholder="Select Employee"
                >
                  <Option value="ow">Owner</Option>
                  <Option value="mg">Manager</Option>
                  <Option value="hr">HR</Option>
                </Select>
              </Form.Item>
            </div>
            <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px', width: '50%' }}
            >
              <label htmlFor="inputState" className="form-label">
                Number Of Employee
              </label>
              <Form.Item
                name="noofEmp"
                rules={[
                  {
                    required: false,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Enter Number Of Employee',
                  },
                ]}
              >
                <Input
                  type="number"
                  min="0"
                  onChange={(e: any) => setNoofEmp(e.target.value)}
                  placeholder="Enter Number Of Employee"
                />
              </Form.Item>
            </div>
          </div>
          {/* <div className="form-row">
            
          </div> */}
          <div className="button-group">
            <Button
              className="cancelButton"
              onClick={() => setDrawerVisible(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="saveButton border-0"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

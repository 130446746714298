/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-use-before-define */
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Size = Quill.import('attributors/style/size')
const sizeList = ['10px', '12px', '14px']
Size.whitelist = sizeList
Quill.register(Size, true)

// const { Quill } = ReactQuill
const Font = Quill.import('attributors/class/font')
const fontList = [
  'Calibri Regular',
  'Calibri Bold',
  'Calibri Italic',
  'Calibri Bold Italic',
  'Calibri Light',
  'Calibri Light Italic',
  'serif',
  'sans-serif',
  'monospace',
  'cursive',
  'fantasy',
  'system-ui',
  'ui-serif',
  'ui-sans-serif',
  'ui-monospace',
  'ui-rounded',
  'emoji',
  'math',
  'fangsong',
]
Font.whitelist = fontList.map((font) => getFontName(font))
Quill.register(Font, true)

let fontStyles = ''
fontList.forEach(function (font) {
  const fontName = getFontName(font)
  fontStyles +=
    `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before {` +
    `content: '${font}';` +
    `font-family: '${font}', sans-serif;` +
    `}` +
    `.ql-font-${fontName}{` +
    ` font-family: '${font}', sans-serif;` +
    `}`
})

function getFontName(font: any): any {
  return font.toLowerCase().replace(/\s/g, '-')
}

let sizeStyles = ''
sizeList.forEach(function (size) {
  const sizeName = size
  sizeStyles +=
    `.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=${sizeName}]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=${sizeName}]::before {` +
    `content: '${size}';` +
    `font-size: '${size}' !important;` +
    `}` +
    `.ql-size-${sizeName}{` +
    ` font-size: '${size}' !important;` +
    `}`
})

const node = document.createElement('style')
node.innerHTML = fontStyles
node.innerHTML = sizeStyles
document.body.appendChild(node)

interface Props {
  updateQuestionList: any
  defaultEditorState: any
  questionIndex: any
}

export const HTMLEditor: React.FC<Props> = ({
  updateQuestionList,
  defaultEditorState,
  questionIndex,
}): JSX.Element => {
  // eslint-disable-next-line prettier/prettier
  // console.log('defaultEditorState', defaultEditorState)

  const modules = {
    toolbar: [
      // [{ header: '1' }, { header: '2' }],
      [{ font: Font.whitelist }],
      [{ size: Size.whitelist }],
      [{ header: [1, 2, 3, 4, 5, 6, 7] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  }
  const formats = [
    'header',
    'font',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]
  return (
    <div className="mt-2 mb-2">
      <ReactQuill
        key={questionIndex}
        // name={questionIndex}
        theme="snow"
        defaultValue={defaultEditorState || ''}
        onChange={(e: any) => updateQuestionList(e, 'html-editor')}
        formats={formats}
        modules={modules}
      />
      <h5 className="text-break">
        Short Code:
        &#36;&#123;first_name&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;last_name&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;employee_name&#125;
      </h5>
    </div>
  )
}

/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Switch,
  Alert,
  notification,
  Tooltip,
  Drawer,
  DatePicker,
} from 'antd'
import {
  EditOutlined,
  QuestionCircleFilled,
  CloseOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { Form, FormCheck } from 'react-bootstrap'
import ReactQuill, { Quill } from 'react-quill'
import moment from 'moment'
import editIcon from '../../../../../assets/images/Icon open-pencil.svg'
import { updateProjectDetail } from '../../../../../api/API'
import { readCookie } from '../../../../../api/CookieScript'
import {
  COMPANY_ROLE,
  ROLE_KEYWORD,
  SUPER_ADMIN_ROLE,
} from '../../../../../constants/index'

const Size = Quill.import('attributors/style/size')
const sizeList = ['10px', '12px', '14px']
Size.whitelist = sizeList
Quill.register(Size, true)

// const { Quill } = ReactQuill
const Font = Quill.import('attributors/class/font')
const fontList = [
  'Calibri Regular',
  'Calibri Bold',
  'Calibri Italic',
  'Calibri Bold Italic',
  'Calibri Light',
  'Calibri Light Italic',
  'serif',
  'sans-serif',
  'monospace',
  'cursive',
  'fantasy',
  'system-ui',
  'ui-serif',
  'ui-sans-serif',
  'ui-monospace',
  'ui-rounded',
  'emoji',
  'math',
  'fangsong',
]
Font.whitelist = fontList.map((font) => getFontName(font))
Quill.register(Font, true)

let fontStyles = ''
fontList.forEach(function (font) {
  const fontName = getFontName(font)
  fontStyles +=
    `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before {` +
    `content: '${font}';` +
    `font-family: '${font}', sans-serif;` +
    `}` +
    `.ql-font-${fontName}{` +
    ` font-family: '${font}', sans-serif;` +
    `}`
})

function getFontName(font: any): any {
  return font.toLowerCase().replace(/\s/g, '-')
}

let sizeStyles = ''
sizeList.forEach(function (size) {
  sizeStyles +=
    `.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=${size}]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=${size}]::before {` +
    `content: '${size}';` +
    `font-size: '${size}' !important;` +
    `}` +
    `.ql-size-${size}{` +
    ` font-size: '${size}' !important;` +
    `}`
})

const node = document.createElement('style')
node.innerHTML = fontStyles
node.innerHTML = sizeStyles
document.body.appendChild(node)

interface Prop {
  details: any
  projectId: any
}

export const ProjectDetailFrom: React.FC<Prop> = ({
  details,
  projectId,
}): JSX.Element => {
  const dateFormat = 'YYYY/MM/DD'
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [description, setDescription] = useState('')
  const [manageBranding, setManageBranding] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  // const [formname, setFormName] = useState(name)
  // const [formphone, setFormPhone] = useState('')
  // const [formemail, setFormEmail] = useState('')
  // const [formstartDate, setFormStartDate] = useState('')
  // const [formendDate, setFormEndDate] = useState('')
  // const [formdescription, setFormDescription] = useState('')
  // const [formmanageBranding, setFormManageBranding] = useState(false)

  const showModal = (): any => {
    setIsModalVisible(true)
  }

  const handleCancel = (): any => {
    setIsModalVisible(false)
  }

  // const formStartDate = details.startDate.split('T')[0]
  // const formEndDate = details.endDate.split('T')[0]

  useEffect(() => {
    if (details) {
      setName(details.name)
      setPhone(details.phone)
      setEmail(details.email)
      setDescription(details.description)
      setManageBranding(details.manage_branding)
      if (details.start_date) {
        setStartDate(details.start_date.split('T')[0])
        setEndDate(details.end_date.split('T')[0])
      }
    }
  }, [details])

  const handleAddProject = (): void => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    const data = {
      name,
      email,
      phone,
      start_date: startDate,
      end_date: endDate,
      description,
      manage_branding: manageBranding,
    }
    // console.log(data)
    if (
      name !== '' &&
      // phone !== '' &&
      email !== '' &&
      email.match(emailRegex) &&
      startDate !== '' &&
      endDate !== ''
    ) {
      updateProjectDetail(projectId, data)
        .then((res: any): any => {
          const result = res.data
          notification.success({
            message: result.message,
            description: '',
          })
        })
        .catch((error: any) => {
          if (error.response.status === 401) {
            notification.error({
              message: 'Please Login to continue',
              description: '',
            })
          } else {
            notification.error({
              message: 'Something went wrong',
              description: '',
            })
          }
        })
      setIsModalVisible(false)
    } else {
      setErrorMsg('Please Enter Required field')
      setIsError(true)
    }
  }
  const modules = {
    toolbar: [
      // [{ header: '1' }, { header: '2' }],
      [{ font: Font.whitelist }],
      [{ size: Size.whitelist }],
      [{ header: [1, 2, 3, 4, 5, 6, 7] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  }
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  return (
    <div className="border p-3 bg-gray-color project-form">
      {/* <Alert
        message={errorMsg}
        type="error"
        style={{ display: isError ? 'block' : 'none' }}
      /> */}
      <Row>
        <Col span={15}>
          <b>Project Details</b>
          <p>Please fill in the below details for new project</p>
        </Col>
        <Col span={9} className="text-end">
          {/* {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
            readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && ( */}
          {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
            <>
              <Button onClick={showModal}>
                <img src={editIcon} alt="Edit" className="pe-2 editIcon" />
                Edit
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Form className="mt-4">
        <Form.Group className="mb-3">
          <Form.Label>Project Name</Form.Label>
          <Form.Control
            type="text"
            defaultValue={details.name}
            // onChange={(e) => setName(e.target.value)}
            readOnly
          />
        </Form.Group>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} lg={12}>
            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <input
                type="date"
                // format={dateFormat}
                className="w-100 date-picker-and-design"
                defaultValue={
                  details.start_date ? details.start_date.split('T')[0] : ''
                }
                // onChange={(e) => setStartDate(e.target.value)}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Group className="mb-3">
              <Form.Label>End Date</Form.Label>
              <br />
              <input
                type="date"
                // format={dateFormat}
                className="w-100 date-picker-and-design"
                defaultValue={
                  details.end_date ? details.end_date.split('T')[0] : ''
                }
                // onChange={(e) => setEndDate(e.target.value)}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {/* <Col xs={24} md={12} lg={12}>
            <Form.Group className="mb-3">
              <Form.Label>
                Phone
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={details.phone}
                // onChange={(e) => setPhone(e.target.value)}
                readOnly
              />
            </Form.Group>
          </Col> */}
          <Col xs={24} md={12} lg={12}>
            <Form.Group className="mb-3">
              <Form.Label>Contact Email</Form.Label>
              <Form.Control
                type="email"
                defaultValue={details.email}
                // onChange={(e) => setEmail(e.target.value)}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2 mb-3">
          <Col span={6}>
            <b>Manage Branding</b>
          </Col>
          <Col span={8}>
            <p>Hide GM 360 Branding</p>
          </Col>
          <Col span={4}>
            <Switch
              checked={details.manage_branding}
              // onChange={() => setManageBranding(!manageBranding)}
            />
          </Col>
          <Col span={4}>
            <p>Show Branding</p>
            {/* <Tooltip title="tooltip content goes here">
                <QuestionCircleFilled className="ms-1" />
              </Tooltip> */}
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Description</Form.Label>
          {/* <Form.Control
            as="textarea"
            rows={8}
            defaultValue={details.description}
            // onChange={(e) => setDescription(e.target.value)}
            readOnly
          /> */}
          <ReactQuill
            theme="snow"
            formats={formats}
            modules={modules}
            className="inputBorder"
            value={details.description ? details.description : ' '}
            readOnly
            // onChange={(e) => setDescription(e)}
          />
        </Form.Group>
      </Form>

      <Drawer
        title="Edit Project Detail"
        visible={isModalVisible}
        closeIcon={<CloseOutlined onClick={() => setIsModalVisible(false)} />}
        width={window.innerWidth > 550 ? 465 : '100%'}
        className="ProjectModal"
      >
        <Alert
          message={errorMsg}
          type="error"
          style={{ display: isError ? 'block' : 'none' }}
        />
        <Form className="mt-4 px-3">
          <Form.Group className="mb-3">
            <Form.Label>
              Project Name
              <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Start Date
                  <sup className="text-danger">*</sup>
                </Form.Label>
                {/* <input
                  type="date"
                  // format={dateFormat}
                  className="w-100 date-picker-ant-design"
                  defaultValue={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                /> */}
                <DatePicker
                  className="w-100"
                  value={startDate !== '' ? moment(startDate) : null}
                  onChange={(date, dateString) => setStartDate(dateString)}
                />
              </Form.Group>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  End Date
                  <sup className="text-danger">*</sup>
                </Form.Label>
                <br />
                {/* <input
                  type="date"
                  // format={dateFormat}
                  className="w-100 date-picker-ant-design"
                  defaultValue={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                /> */}
                <DatePicker
                  className="w-100"
                  // value={endDate}
                  value={endDate !== '' ? moment(endDate) : null}
                  onChange={(date, dateString) => setEndDate(dateString)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* <Col xs={24} md={12} lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Phone
                  <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
            </Col> */}
            <Col xs={24} md={12} lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Contact Email
                  <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="email"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Col span={24}>
              <b>
                Manage Branding
                {/* <sup className="text-danger">*</sup> */}
              </b>
            </Col>
            <Col span={9} className="mt-2">
              <p>Hide GM 360 Branding</p>
            </Col>
            <Col span={4} className="mt-2">
              <Switch
                checked={!!manageBranding}
                onChange={() => setManageBranding(!manageBranding)}
              />
            </Col>
            <Col span={7} className="mt-2">
              <p>
                Show Branding
                {/* <Tooltip title="tooltip content goes here">
                  <QuestionCircleFilled className="ms-1" />
                </Tooltip> */}
              </p>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            {/* <Form.Control
              as="textarea"
              rows={8}
              defaultValue={description}
              onChange={(e) => setDescription(e.target.value)}
            /> */}
            <ReactQuill
              theme="snow"
              formats={formats}
              modules={modules}
              className="inputBorder"
              defaultValue={description || ''}
              onChange={(e) => setDescription(e)}
            />
          </Form.Group>
          <div className="d-flex justify-content-end pb-3 me-3">
            <Button
              onClick={() => setIsModalVisible(false)}
              className="float-right me-2 cancel-btn"
            >
              Cancel
            </Button>
            <Button onClick={handleAddProject} className="float-right save-btn">
              Save
            </Button>
          </div>
        </Form>
      </Drawer>
    </div>
  )
}

import { Button, Drawer, Popconfirm, Row, Col } from 'antd'
import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import {
  // InsertRowRightOutlined,
  UserSwitchOutlined,
  QuestionCircleFilled,
  InfoCircleOutlined,
} from '@ant-design/icons'
import headIcon from '../../../assets/images/Group 9264.svg'
import empHeadIcon from '../../../assets/images/Group 9011.svg'
import userIcon from '../../../assets/images/users.svg'
import './style/header.scss'
import arrowDown from '../../../assets/images/arrow_down.svg'
import BuldingDark from '../../../assets/images/buildingsgray.svg'
import avatarimg from '../../../assets/images/Icon awesome-user-circle.svg'
import empIcon from '../../../assets/images/Icon feather-users.svg'
import rightArwIcon from '../../../assets/images/Icon ionic-ios-arrow-back.svg'
import arwDivider from '../../../assets/images/Path 26578.svg'
import { EmployeeForm } from './employeeForm'
import DrawerFooter from '../../../components/Drawer/footer/drawerFooter'
import { ParticipantForm } from './participantForm'
import { AddProject } from './addProject'
import { readCookie } from '../../../api/CookieScript'
import {
  COMPANY_ROLE,
  ROLE_KEYWORD,
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
} from '../../../constants/index'

interface Props {
  company: any
  getCompanyDetail: any
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  setProjectDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  setParticipantDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
}
const CompanyHeader: React.FC<Props> = ({
  company,
  getCompanyDetail,
  setDrawerVisible,
  setProjectDrawerVisible,
  setParticipantDrawerVisible,
}): JSX.Element => {
  const params = useParams<any>()
  const { id } = params // project id

  const [data, setData] = useState(company)
  useEffect(() => {
    setData(company)
  })
  return (
    <div className="headerarea">
      <div className="header-area-left">
        <div className="header-title-wrapper">
          <span className="mobile-heading">
            <span className="text-uppercase header-name">
              {/* {data.name} */}
              COMPANY
            </span>
            <span className="mobile-header-name">
              <b>{data.name}</b>
            </span>
            {/* popconfirm area */}
            <Popconfirm
              title={
                <div>
                  <Row
                    gutter={[12, 12]}
                    style={{ borderBottom: '2px solid #F4F6F7' }}
                    className="mobile_company_logo_name pb-2"
                  >
                    <Col xs={4} sm={4}>
                      <img
                        src={data.logo ? data.logo : ''}
                        alt="not availabel"
                        className="company-logo mt-2"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50px',
                        }}
                      />
                    </Col>
                    <Col xs={20} sm={20} md={20} className="pt-3">
                      <b>{data.name}</b>
                    </Col>
                  </Row>
                  <Row
                    gutter={[12, 12]}
                    className="mobile_project_id mt-3 justify-content-center"
                  >
                    <Col xs={8} sm={8} className="border-end text-center">
                      <img src={BuldingDark} alt="Company" className="me-1" />
                      {data.company_id}
                    </Col>
                    <Col xs={6} sm={6} className="border-end text-center">
                      <img
                        src={userIcon}
                        alt="users"
                        className="emp-img me-1 "
                      />
                      {/* {data.number_of_employee} */}
                      {data.totalParticipant + data.totalEmployee}
                    </Col>
                    <Col
                      xs={10}
                      sm={10}
                      className="text-center d-flex justify-content-center"
                    >
                      {/* <img
                        src={data.logo || avatarimg}
                        alt="not availabel"
                        className="user-img"
                        style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '30px',
                          marginRight: '8px',
                        }}
                      /> */}
                      <p className="user-name">{data.contact_person}</p>
                    </Col>
                  </Row>
                </div>
              }
              placement="bottom"
              overlayStyle={{ width: '98vw' }}
            >
              <Button
                id="info_button"
                ghost
                type="primary"
                icon={<InfoCircleOutlined />}
              >
                info
              </Button>
            </Popconfirm>
            {/* popconfirm area over */}
            {/* <Button className="float-end header-add-btn">Add</Button> */}
          </span>
          <div className="report-header-subtitle">
            <div className="header-links">
              {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
                <>
                  <Link className="company-profile-header-link" to="/dashboard">
                    Dashboard
                  </Link>
                  <img src={rightArwIcon} alt="Right" className="px-2" />
                </>
              )}
              <Link className="company-profile-header-link" to="/company">
                Company
              </Link>
              <img src={rightArwIcon} alt="Right" className="px-2" />
              <Link
                className="company-profile-header-link"
                // to="/company-detail"
                to={{ pathname: `/company-detail/${id}` }}
              >
                {data.name}
              </Link>
            </div>
          </div>
          {/* DropDown start for mobile */}
          <hr className="mobile-hr" />
          <div className="d-flex mobile-dropdowns">
            <Dropdown className="header-add-btns">
              <Dropdown.Toggle className="border-0">
                Add Users
                <img src={arrowDown} alt="arrow" className="dropdown-arrow" />
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setDrawerVisible(true)
                    }}
                  >
                    <img
                      src={empHeadIcon}
                      alt="Employee"
                      className="w-15 pe-1 me-2"
                    />
                    Employee(s)
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setParticipantDrawerVisible(true)
                    }}
                  >
                    <img
                      src={empIcon}
                      alt="Participant(s)"
                      className="w-15 me-2"
                    />
                    Participant(s)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown.Toggle>
            </Dropdown>
            <Button
              className="header-add-btn"
              onClick={() => {
                setProjectDrawerVisible(true)
              }}
            >
              <span className="header-btn-text">ADD New Project</span>
            </Button>
          </div>
          {/* dropdown over */}
        </div>
        <img src={arwDivider} alt="divider" className="custom-divider" />
        <div className="company-name-wrapper desk-data">
          <div className="position-relative">
            <img src={data.logo} alt="not availabel" className="company-logo" />
            <div className="card-div3">
              <div>
                {data.mode && (
                  <div
                    className="live-status-dot"
                    style={{ background: '#2EEC74' }}
                  />
                )}
                {!data.mode && (
                  <div
                    className="live-status-dot"
                    style={{ background: 'transparent' }}
                  />
                )}
              </div>
            </div>
          </div>
          <span className="company-name ms-2">{data.name}</span>
        </div>
        <div className="company-id-wrapper desk-data">
          <img src={BuldingDark} alt="Company" />
          <span className="company-id ms-2">{data.company_id}</span>
        </div>
        <div className="company-emp-wrapper desk-data">
          <img src={userIcon} alt="users" className="emp-img" />
          <span className="emp-num ms-2">
            {data.totalParticipant + data.totalEmployee}
          </span>
        </div>
        <div className="company-user-wrapper desk-data">
          {/* <img src={data.logo} alt="not availabel" className="user-img" /> */}
          <span className="company-user-name ms-2">{data.contact_person}</span>
        </div>
      </div>
      <div className="header-area-right desk-data">
        <Dropdown>
          <Dropdown.Toggle
            className=" rater-allproject-dropdown"
            id="dropdown-basic2"
          >
            <span className="add-user-dropdown-text">Add User</span>
            <img src={arrowDown} alt="arrow" className="company-arrow_down" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              href="#"
              onClick={() => {
                setDrawerVisible(true)
              }}
            >
              <img
                src={empHeadIcon}
                alt="Employee"
                className="w-15 pe-1 me-2"
              />
              Employee(s)
            </Dropdown.Item>
            <Dropdown.Item
              href="#"
              onClick={() => {
                setParticipantDrawerVisible(true)
              }}
            >
              <img src={empIcon} alt="Participant(s)" className="w-15 me-2" />
              Participant(s)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
          <>
            <Button
              className="header-btn"
              onClick={() => {
                setProjectDrawerVisible(true)
              }}
            >
              <span className="header-btn-text">ADD New Project</span>
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default CompanyHeader

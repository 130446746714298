/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  CloseOutlined,
  UserOutlined,
  DownCircleOutlined,
  UpCircleOutlined,
} from '@ant-design/icons'
import { Form } from 'react-bootstrap'
import {
  Table,
  Drawer,
  Input,
  Modal,
  Row,
  Cascader,
  Col,
  Button,
  Collapse,
  Avatar,
  DatePicker,
  Select,
  Typography,
  notification,
  Spin,
  Checkbox,
} from 'antd'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import ReactQuill, { Quill } from 'react-quill'
import {
  getEmployeeTaleData,
  getLastSharedRemainderDataApi,
  getEmployeeUserTaleDataApi,
  // sendRemainderDataApi,
  // getAllRaters,
  getListOfCascader,
  addDistributionData,
  getRatersAndParticipantsDataApi,
  getDataAndAnalysysFilter,
  exportDataToCSV,
  getAllRaters,
} from '../../../../../api/API'
import MoreImge from '../../../../../assets/images/more.svg'
import rightImge from '../../../../../assets/images/right.png'
import wrongImge from '../../../../../assets/images/wrong.png'
import { readCookie } from '../../../../../api/CookieScript'
import {
  COMPANY_ROLE,
  SUPER_ADMIN_ROLE,
  ROLE_KEYWORD,
  EMPLOYEE_ROLE,
} from '../../../../../constants'
import FilterBar from './filterbar'

// const { Quill } = ReactQuill
const Font = Quill.import('attributors/class/font')
const fontList = [
  'Calibri Regular',
  'Calibri Bold',
  'Calibri Italic',
  'Calibri Bold Italic',
  'Calibri Light',
  'Calibri Light Italic',
  'serif',
  'sans-serif',
  'monospace',
  'cursive',
  'fantasy',
  'system-ui',
  'ui-serif',
  'ui-sans-serif',
  'ui-monospace',
  'ui-rounded',
  'emoji',
  'math',
  'fangsong',
]
Font.whitelist = fontList.map((font) => getFontName(font))
Quill.register(Font, true)

let fontStyles = ''
fontList.forEach(function (font) {
  const fontName = getFontName(font)
  fontStyles +=
    `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before {` +
    `content: '${font}';` +
    `font-family: '${font}', sans-serif;` +
    `}` +
    `.ql-font-${fontName}{` +
    ` font-family: '${font}', sans-serif;` +
    `}`
})

function getFontName(font: any): any {
  return font.toLowerCase().replace(/\s/g, '-')
}

const node = document.createElement('style')
node.innerHTML = fontStyles
document.body.appendChild(node)

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input
const { Panel } = Collapse
const dateFormat = 'YYYY/MM/DD'

const { Paragraph } = Typography
export const EmployeeTable: React.FC = () => {
  // get id
  const params = useParams<any>()
  const myRef = useRef<any>()

  // const [employeeID, setEmployeeId] = useState('')
  const [employeeData, setEmployeeData] = useState([])
  const [remainderData, setRemainderData] = useState()
  const [selectedEmployee, setSelectedEmployee] = useState()
  const [ReminderModal, setReminderModal] = useState<boolean>(false)
  const [isRateModalVisible, setIsRateModalVisible] = useState(false)
  const [ratersAndParticipantList, setRatersAndParticipantList] = useState([])
  const [selfRaterID, setSelfRaterID] = useState('')
  const [rerenderState, setRerenderState] = useState(Math.random())

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [csvFileName, setCsvFileName] = useState('file.csv')

  // states for distribution form
  const { id } = params // project id

  // const [to, setTo] = useState([])
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [fromName, setFromName] = useState('')
  const [fromEmail, setFromEmail] = useState('test@gmail.com')
  const [replyToEmail, setReplyToEmail] = useState('test@gmail.com')
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')
  const [formData, setFormData] = useState({})
  const [cascader, setCascader] = useState([{}])
  const [originalAPIdata, setOriginalAPIdata] = useState<any>([])
  const [selectedEmpId, setSelectedEmpId] = useState<any>([])
  const [selectedParticipantId, setSelectedParticipantId] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState(true)

  const [csvData, setCsvData] = useState<any>([])
  const [csvHeader, setHeader] = useState<any>([])

  const [fromEmailErr, setFromEmailErr] = useState('')
  const [fromNameErr, setFromNameErr] = useState('')
  const [replyToEmailErr, setReplyToEmailErr] = useState('')
  const [subjectErr, setSubjectErr] = useState('')
  const [cascaderErr, setCascaderErr] = useState('')
  const [dateErr, setDateErr] = useState('')
  const [timeErr, setTimeErr] = useState('')
  // states for distribution form  are over

  const [tempArray, setTempArray] = useState<any>([])

  const [selectedUSersFromDB, setSelectedUSersFromDB] = useState<any>([])

  const [directMailSending, setDirectMailSending] = useState<boolean>(false)

  // methods for distribution form  are over
  // fill api values in cascader
  const getCascaderData = (notResList: any, empid: any): any => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getListOfCascader(id).then(async (res: any) => {
      setOriginalAPIdata(res.data.data)
      const casdata = res.data.data
      console.log('casdata', casdata)

      const dataMap = []
      for (let i = 0; i < casdata.length; i++) {
        const subdata = []
        if (empid.toString() === casdata[i]._id.toString()) {
          for (let j = 0; j < casdata[i].raters.length; j++) {
            const patlist = []
            for (let k = 0; k < casdata[i].raters[j].participant.length; k++) {
              patlist.push({
                code: casdata[i].raters[j].participant[k]._id,
                name: casdata[i].raters[j].participant[k].name,
              })
            }
            subdata.push({
              code: casdata[i].raters[j]._id,
              name: casdata[i].raters[j].title,
              items: patlist,
            })
          }
          dataMap.push({
            code: casdata[i]._id,
            name: casdata[i].first_name,
            items: subdata,
          })
        }
      }
      setCascader(dataMap)
      let selfId
      await getAllRaters(id).then((res1: any) => {
        const result = res1.data.data
        const selfData = result.find(
          (rater: any) => rater.title.toLowerCase() === 'self',
        )
        selfId = selfData._id
      })

      const empList = []
      for (let emp = 0; emp < casdata.length; emp++) {
        for (let rater = 0; rater < casdata[emp].raters.length; rater++) {
          if (notResList.includes(casdata[emp]._id.toString())) {
            if (selfId) {
              empList.push([casdata[emp]._id, selfId])
            }
          }
          const participantCount = casdata[emp].raters[rater].participant
          if (participantCount.length > 0) {
            for (let parti = 0; parti < participantCount.length; parti++) {
              // console.log('abc', participantCount[parti]._id)
              if (notResList.includes(participantCount[parti]._id.toString())) {
                // console.log('dasda', participantCount[parti]._id)
                empList.push([
                  casdata[emp]._id,
                  casdata[emp].raters[rater]._id,
                  participantCount[parti]._id,
                ])
              }
            }
          }
        }
      }
      setSelectedUSersFromDB(empList)
      setRerenderState(Math.random())
      setReminderModal(true)
    })
  }

  useEffect(() => {
    getEmployees()
    // getCascaderData()
  }, [])

  useEffect(() => {
    getAllRaters(params.id).then((res: any) => {
      // setRaterList(res.data.data)
      const result = res.data.data
      const selfData = result.find(
        (rater: any) => rater.title.toLowerCase() === 'self',
      )
      setSelfRaterID(selfData._id)
    })
  }, [])

  useEffect(() => {
    if (directMailSending) {
      const todayData = new Date()
      const hr = todayData.getHours()
      const min =
        todayData.getMinutes() < 10
          ? `0${todayData.getMinutes()}`
          : todayData.getMinutes()
      const defaultTime = `${hr}:${min}`
      const defaultDate = todayData.toLocaleDateString('en-CA')

      setTime(defaultTime)
      setDate(defaultDate)
    }
  }, [directMailSending])

  // cascader search filter
  const filter = (inputValue: any, path: any): any => {
    return path.some(
      (option: any) =>
        option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    )
  }
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  // handle Submit Method
  const handleSubmit = (): any => {
    setCascaderErr('')
    setDateErr('')
    setTimeErr('')
    setFromNameErr('')
    setSubjectErr('')
    setFromEmailErr('')
    setReplyToEmailErr('')

    // console.log("call 333")
    // console.log("selectedEmpId",selectedEmpId);
    // console.log("date",date);
    // console.log("selectedParticipantId",selectedParticipantId);
    // console.log("time",time);
    // console.log("fromName",fromName);
    // console.log("subject",subject);
    // console.log("fromEmail",fromEmail);
    // console.log("replyToEmail",replyToEmail);

    // check Validation
    let valid = true

    const totalLength = selectedEmpId.length + selectedParticipantId.length
    if (totalLength == 0) {
      setCascaderErr('Please Select Employee,Participant')
      valid = false
    }
    // if (selectedEmpId.length == 0 && selectedParticipantId.length == 0) {
    //   setCascaderErr('Please Select Employee,Participant')
    //   valid = false
    // }
    if (date == '') {
      setDateErr('Please Select Date')
      valid = false
    }

    const today = new Date()
    const dd = today.getDate()
    const mm = today.getMonth() + 1 // January is 0!
    const yyyy = today.getFullYear()
    const newDate = `${yyyy}-${mm}-${dd}`

    if (new Date(date).getTime() < new Date(newDate).getTime()) {
      // setDateErr('Please Select Current or Future Date')
      // valid = false
    }

    if (time == '') {
      setTimeErr('Please Select Time')
      valid = false
    }

    if (fromName == '') {
      setFromNameErr('Please Enter From Name')
      valid = false
    }
    if (subject == '') {
      setSubjectErr('Please Enter Valid Subject')
      valid = false
    }
    if (fromEmail == '' || !fromEmail.match(emailRegex)) {
      setFromEmailErr('Please Enter Valid Email')
      valid = false
    }
    if (replyToEmail == '' || !replyToEmail.match(emailRegex)) {
      setReplyToEmailErr('Please Enter Valid Email')
      valid = false
    }

    if (valid) {
      const formdata = {
        project_id: id,
        employee: selectedEmpId,
        participants: selectedParticipantId,
        directMailSending,
        date,
        time,
        form_name: fromName,
        from_email: fromEmail,
        replay_to_email: replyToEmail,
        subject,
        description,
      }
      // console.log(formdata)

      // pass distribution data to api
      addDistributionData(formdata).then((res: any): any => {
        if (res.status !== 200) {
          notification.error({
            message: 'something went wrong refresh pages',
          })
        } else {
          setSelectedParticipantId([])
          // history.push('/project-detail')
          notification.success({
            message: 'Distribution successfully Added',
          })
          setRerenderState(Math.random())
          setDirectMailSending(false)
          setDate('')
          setTime('')
          // setFromEmail('')
          // setReplyToEmail('')
          setFromName('')
          setSubject('')
          setDescription('')
          setReminderModal(false)
        }
      }) // api function over
    } // if over
  }
  const modules = {
    toolbar: [
      // [{ header: '1' }, { header: '2' }],
      [{ font: Font.whitelist }],
      [{ header: [1, 2, 3, 4, 5, 6, 7] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  }
  const formats = [
    'header',
    'font',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]
  const options = cascader
  const cascaderFun = (choosenoption: any): any => {
    const empIds = []
    const patIds = []
    for (let index = 0; index < choosenoption.length; index++) {
      const element = choosenoption[index]

      if (element.length > 1) {
        // set participation Ids in state
        // const participationid = element.length === 3 ? element[2] : element[1]
        // if (patIds.indexOf(participationid) === -1) {
        //   patIds.push(participationid)
        // }
        // // set employee Ids in state
        // const employeeid = element[0]
        // if (empIds.indexOf(employeeid) === -1) {
        //   empIds.push(employeeid)
        // }

        if (element.length === 3) {
          const participationid = element[2]
          if (patIds.indexOf(participationid) === -1) {
            patIds.push(participationid)
          }
        }

        if (element.length === 2) {
          for (let index2 = 0; index2 < originalAPIdata.length; index2++) {
            if (originalAPIdata[index2]._id === element[0]) {
              for (
                let index3 = 0;
                index3 < originalAPIdata[index2].raters.length;
                index3++
              ) {
                for (
                  let index4 = 0;
                  index4 <
                  originalAPIdata[index2].raters[index3].participant.length;
                  index4++
                ) {
                  if (
                    element[1].toString() ===
                    originalAPIdata[index2].raters[index3]._id.toString()
                  ) {
                    patIds.push(
                      originalAPIdata[index2].raters[index3].participant[index4]
                        ._id,
                    )
                  }
                  // else {
                  //   patIds.push(
                  //     originalAPIdata[index2].raters[index3].participant[index4]
                  //       ._id,
                  //   )
                  // }
                }
              }
            }
          }
        }

        const employeeid = element[0]
        const selfid = element[1]
        if (selfRaterID === selfid) {
          if (empIds.indexOf(employeeid) === -1) {
            empIds.push(employeeid)
          }
        }
      } else {
        // set employee Ids in state
        const employeeid = element[0]
        if (empIds.indexOf(employeeid) === -1) {
          empIds.push(employeeid)
        }

        for (let index2 = 0; index2 < originalAPIdata.length; index2++) {
          if (originalAPIdata[index2]._id === employeeid) {
            for (
              let index3 = 0;
              index3 < originalAPIdata[index2].raters.length;
              index3++
            ) {
              for (
                let index4 = 0;
                index4 <
                originalAPIdata[index2].raters[index3].participant.length;
                index4++
              ) {
                patIds.push(
                  originalAPIdata[index2].raters[index3].participant[index4]
                    ._id,
                )
              }
            }
          }
        }
      }
    }

    console.log('empIds', empIds)
    console.log('patIds', patIds)

    setSelectedEmpId(empIds)
    setSelectedParticipantId(patIds)
  }
  // methods for distribution form  are over

  const getEmployees = (): any => {
    setLoading(true)
    const data = {
      page_no: currentPage,
    }
    if (readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE) {
      // this method call only login user is employee
      getEmployeeUserTaleDataApi(id).then((res: any): any => {
        setLoading(false)
        setEmployeeData(res.data.data)
      })
    } else {
      // this method call for super admin & company login user
      getEmployeeTaleData(id, data).then((res: any): any => {
        setLoading(false)
        setEmployeeData(res.data.data.data)
        setTotalPages(res.data.data.totalPages)
      })
    }
  }

  const openReminderDrawer = (empid: any): void => {
    const notResList: any = []
    const empdata = {
      employee_id: empid,
    }
    getRatersAndParticipantsDataApi(id, empdata).then((res: any) => {
      const { data } = res.data
      console.log(data)
      setRatersAndParticipantList(res.data.data)
      for (let i = 0; i < data.length; i++) {
        // console.log(ratersAndParticipantList[0])
        if (data[i].participant.length > 0) {
          const participants = data[i].participant
          for (let index = 0; index < participants.length; index++) {
            const element = participants[index]

            if (data[i].responses.length > 0) {
              const { responses } = data[i]
              const filterData = responses.find(
                (element2: any) =>
                  element2.participant_id.toString() ===
                    element._id.toString() && element2.is_completed == true,
              )

              if (!filterData) {
                if (element.employee_id.toString() === empid.toString()) {
                  notResList.push(element._id.toString())

                  const temp = selectedParticipantId
                  temp.push(element._id)
                  setSelectedParticipantId(temp)
                }
              }
            } else if (element.employee_id.toString() === empid.toString()) {
              notResList.push(element._id.toString())

              const temp = selectedParticipantId
              temp.push(element._id)
              setSelectedParticipantId(temp)
            }
          }
        }
      }
      getCascaderData(notResList, empid)
    })
  }

  // get raters & Participants data for response modal
  const getRatersAndParticipants = (empid: any): any => {
    setSelectedEmployee(empid)
    const empdata = {
      employee_id: empid,
    }
    getRatersAndParticipantsDataApi(id, empdata).then((res: any) => {
      const { data } = res.data
      console.log(data)
      setRatersAndParticipantList(res.data.data)
      for (let i = 0; i < data.length; i++) {
        // console.log(ratersAndParticipantList[0])
        if (data[i].participant.length > 0) {
          setResponse(false)
        }
      }
    })

    // get last share remainder api data
    const apidata = {
      project_id: id,
      employee_id: empid,
    }
    getLastSharedRemainderDataApi(apidata).then((res: any): any => {
      setRemainderData(res.data.data)
    })
    setIsRateModalVisible(true)
  }

  const resetFilter = (): void => {
    setFilterQuery({})
    getEmployees()
  }

  const filterData = (key: string, value: any): void => {
    const existingQury = filterQuery
    if (key === 'name') {
      existingQury[key] = value.target.value
    }
    if (key === 'mode') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }

    if (key === 'date_added' && value !== null) {
      existingQury[key] = value
    }

    if (key === 'name' && value.target.value === '') {
      getEmployees()
      return
    }

    setFilterQuery(existingQury)

    getDataAndAnalysysFilter(id, existingQury).then((res: any): any => {
      setEmployeeData(res.data.data.data)
    })
  }

  const exportDataToCsv = (employeeId: any, record: any): void => {
    setLoading(true)

    const data = {
      employee_id: employeeId,
    }
    exportDataToCSV(id, data).then((result: any) => {
      setLoading(false)
      setHeader(result.data.data.header)
      setCsvData(result.data.data.data)
      setCsvFileName(`${record.name}.csv`)
      myRef.current.link.click()
    })
  }

  const onDrawerClose = (): void => {
    setRerenderState(Math.random())
    setCascaderErr('')
    setDirectMailSending(false)
    setDate('')
    setTime('')
    // setFromEmail('')
    // setReplyToEmail('')
    setFromName('')
    setSubject('')
    setSelectedUSersFromDB([])
    setDescription('')
    setSelectedEmpId([])
    setSelectedParticipantId([])
    // setIsModalVisible(false)
  }

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: '_id',
    // },
    {
      title:
        readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE
          ? 'PARTICIPANT NAME'
          : 'EMPLOYEE NAME',
      dataIndex: 'name',
      // render: (text: any) => <a href="/#"> {text} </a>,
    },
    {
      title: 'EMPLOYEE ID',
      dataIndex: 'emp_id',
    },
    {
      title: 'REGISTERED BY',
      dataIndex: 'reg_by',
    },
    {
      title: 'REGISTERED DATE',
      dataIndex: 'registeredDate',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      render: (text: any) => <Paragraph copyable>{text}</Paragraph>,
    },
    // {
    //   title: 'PHONE',
    //   dataIndex: 'phone',
    //   render: (text: any) => text && <Paragraph copyable>{text}</Paragraph>,
    // },
    {
      title: 'PARTICIPANTS',
      dataIndex: 'totalParticipant',
    },
    {
      title: 'RESPONSES',
      dataIndex: 'totalResonse',
      render: (text: any, record: any) => (
        <Button
          type="text"
          onClick={() => {
            getRatersAndParticipants(record._id)
            // setIsRateModalVisible(true)
          }}
          aria-hidden="true"
          className="response-count broder-0"
        >
          {text}
        </Button>
      ),
    },
    // {
    //   title: 'STATUS',
    //   dataIndex: 'status',
    // },
    {
      render: (text: any, record: any) => (
        <div className="d-flex justify-content-between">
          <Button
            onClick={() => {
              openReminderDrawer(record._id)
              // handleSendRemainder(record._id)
              // setEmployeeId(record._id)
              // setRerenderState(Math.random())
              // setReminderModal(true)
            }}
            className="mx-2"
          >
            Send Reminder
          </Button>
          {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
            readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
            <Button className="mx-2">
              <Link target="_blank" to={`/reportPdf/${id}/${record._id}`}>
                Download Report
              </Link>
            </Button>
          )}
          {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
            readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
            <Button
              className="mx-2"
              onClick={() => exportDataToCsv(record._id, record)}
            >
              Download CSV
            </Button>
          )}
          <CSVLink
            headers={csvHeader}
            data={csvData}
            ref={myRef}
            filename={csvFileName}
          />
        </div>
      ),
    },
  ]

  const makeDynamicTable = (): any => {
    if (
      readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
      readCookie(ROLE_KEYWORD) === COMPANY_ROLE
    ) {
      return columns
    }
    return columns.filter(
      (col) =>
        col.dataIndex !== 'totalParticipant' && col.dataIndex !== 'emp_id',
    )
  }

  const disablePastDate = (): any => {
    const dtToday = new Date()
    let month: any = dtToday.getMonth() + 1
    let day: any = dtToday.getDate()
    const year: any = dtToday.getFullYear()

    if (month < 10) month = `0${month.toString()}`
    if (day < 10) day = `0${day.toString()}`
    return `${year}-${month}-${day}`
  }

  // const openDistributionDrower = (): void => {
  //   setReminderModal(true)
  // }

  return (
    <>
      {loading && <Spin className="spinner-code" />}
      <FilterBar
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        filterData={filterData}
        resetFilter={resetFilter}
      />
      <hr className="mt-3" />
      <Table
        // scroll={{ x: true }}
        columns={makeDynamicTable()}
        dataSource={employeeData}
        pagination={false}
        rowKey="_id"
        bordered
      />
      {/* -----send Remainder drawer----- */}
      <Drawer
        footer={null}
        title=""
        closable={false}
        visible={ReminderModal}
        className="reminder-form"
        onClose={() => {
          setReminderModal(false)
        }}
        width={window.innerWidth > 550 ? 465 : '100%'}
      >
        <CloseOutlined
          className="float-right pt-4 pe-3"
          style={{ fontSize: '8px' }}
          onClick={() => setReminderModal(false)}
        />
        <div className="p-3 bg-gray-color project-form">
          <b>Distribution Details</b>
          <p>Please fill in the below details for launching the project</p>
          <Form className="mt-3" key={rerenderState}>
            <Row gutter={[16, 8]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Group className="cascaderStyle">
                  <Form.Label>
                    Email To
                    <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Cascader
                    key={rerenderState}
                    fieldNames={{
                      label: 'name',
                      value: 'code',
                      children: 'items',
                    }}
                    options={options}
                    onChange={cascaderFun}
                    // onChange={cascaderFun}
                    defaultValue={selectedUSersFromDB}
                    multiple
                    placeholder="Please select"
                    className="w-100"
                    showSearch={{ filter }}
                    onSearch={(name) => console.log(name)}
                  />
                  <label className="text-danger">{cascaderErr}</label>
                </Form.Group>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <Form.Group>
                  <Checkbox
                    onChange={(e) => setDirectMailSending(e.target.checked)}
                    checked={directMailSending}
                    key={rerenderState}
                  >
                    <b>Send Now</b>
                  </Checkbox>
                  {/* <label>
                  Direct Mail Sending                
                </label> */}
                  {/* <Input
                  key="formControlDate"
                  checked={directMailSending}
                  type="checkbox"
                  onChange={(e) => setDirectMailSending(e.target.checked)}
                />  
                <b> Direct Mail Sending</b> */}
                </Form.Group>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col xs={12} md={12} lg={12}>
                <Form.Group>
                  <Form.Label>
                    Date
                    <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    key={rerenderState}
                    type="date"
                    className="inputBorder"
                    defaultValue={date}
                    onChange={(e) => setDate(e.target.value)}
                    disabled={directMailSending}
                    min={disablePastDate()}
                  />
                  <label className="text-danger">{dateErr}</label>
                </Form.Group>
              </Col>
              <Col xs={12} md={12} lg={12}>
                <Form.Group>
                  <Form.Label>
                    Time
                    <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    key={rerenderState}
                    type="time"
                    className="inputBorder"
                    defaultValue={time}
                    disabled={directMailSending}
                    onChange={(e) => setTime(e.target.value)}
                  />
                  <label className="text-danger">{timeErr}</label>
                </Form.Group>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              {/* <Col xs={24} md={10} lg={10} xl={12}>
                <Form.Group>
                  <Form.Label>
                    From
                    <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    key={rerenderState}
                    type="email"
                    placeholder="Email"
                    className="inputBorder"
                    defaultValue={fromEmail}
                    onChange={(e) => setFromEmail(e.target.value)}
                  />
                  <label className="text-danger">{fromEmailErr}</label>
                </Form.Group>
              </Col> */}
              <Col xs={24} md={24} lg={24} xl={24}>
                <Form.Group>
                  <Form.Label>
                    From Name
                    <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    key={rerenderState}
                    type="text"
                    placeholder="Name"
                    className="inputBorder"
                    defaultValue={fromName}
                    onChange={(e) => setFromName(e.target.value)}
                  />
                  <label className="text-danger">{fromNameErr}</label>
                </Form.Group>
              </Col>
            </Row>
            <Row gutter={[4, 4]}>
              {/* <Col xs={24} md={24} lg={24}>
                <Form.Group className="mt-0">
                  <Form.Label>
                    Reply To
                    <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    key={rerenderState}
                    placeholder="Reply To"
                    className="inputBorder"
                    defaultValue={replyToEmail}
                    onChange={(e) => setReplyToEmail(e.target.value)}
                  />
                  <label className="text-danger">{replyToEmailErr}</label>
                </Form.Group>
              </Col> */}
              <Col xs={24} md={24} lg={24}>
                <Form.Group className="mt-0">
                  <Form.Label>
                    Subject
                    <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    key={rerenderState}
                    placeholder="Survey for new project"
                    className="inputBorder"
                    defaultValue={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  <label className="text-danger">{subjectErr}</label>
                </Form.Group>
              </Col>
              <Col xs={24} md={24} lg={24} className="mb-2">
                {/* <Form.Group controlId="exampleForm.ControlTextarea1"> */}
                <Form.Label>Description</Form.Label>
                {/* <Form.Control as="textarea" rows={8} className="inputBorder" /> */}
                <ReactQuill
                  theme="snow"
                  key={rerenderState}
                  formats={formats}
                  modules={modules}
                  className="inputBorder"
                  defaultValue={description || ''}
                  onChange={(e: any) => setDescription(e)}
                />
                {/* </Form.Group> */}
              </Col>
              <h4>Shortcode</h4>
              <h5 className="text-break">
                &#36;&#123;first_name&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;last_name&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;l://SurveyURL&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;l://SurveyButton&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;employee_name&#125;
              </h5>
            </Row>
            <div className="text-end">
              <Button
                className="comman-button btn-theme"
                onClick={handleSubmit}
              >
                Launch
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
      {/* -----responses modal-----  */}
      <Modal
        visible={isRateModalVisible}
        footer={null}
        width={450}
        closable={false}
      >
        <CloseOutlined
          style={{ float: 'right' }}
          onClick={() => setIsRateModalVisible(false)}
        />
        <Title level={3}> Raters</Title>
        {/*  */}
        <Collapse
          accordion
          bordered
          expandIcon={({ isActive }) =>
            isActive ? <UpCircleOutlined /> : <DownCircleOutlined />
          }
          style={{ marginTop: 10, background: '#fff' }}
        >
          {ratersAndParticipantList &&
            ratersAndParticipantList.length > 0 &&
            ratersAndParticipantList.map((employee: any, index: any) => (
              <>
                {employee.participant && employee.participant.length > 0 && (
                  <Panel
                    header={employee.title}
                    extra={employee.participant.length}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className="rater-model-panel site-collapse-custom-panel"
                  >
                    {employee.participant.length > 0 &&
                      employee.participant.map((participant: any) => (
                        <Row key={participant._id} className="mb-3">
                          <Col span={3}>
                            <Avatar
                              size={34}
                              icon={<UserOutlined />}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          </Col>
                          <Col span={20}>
                            <b>{participant.name}</b>
                          </Col>
                          <Col span={1}>
                            <img
                              src={
                                participant.responses.length > 0 &&
                                participant.responses[0].is_completed
                                  ? rightImge
                                  : wrongImge
                              }
                              alt={`${
                                participant.responses.length > 0 &&
                                participant.responses[0].is_completed
                                  ? 'success'
                                  : 'true'
                              }`}
                              className="w-100"
                            />
                          </Col>
                        </Row>
                      ))}
                  </Panel>
                )}
              </>
            ))}
        </Collapse>

        {/* ratersAndParticipantList loop over */}
        <Row gutter={[12, 12]} className="mt-3">
          <Col xs={24} sm={24} md={16} lg={15}>
            <div className="last-shared-reminder">
              {/* <Title level={5}>Last Shared Reminder</Title> */}
              {remainderData}
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={9}>
            {/* <Form.Item> */}
            <Button
              className="bg-theme"
              onClick={() => {
                setIsRateModalVisible(false)
                // setReminderModal(true)
                openReminderDrawer(selectedEmployee)
              }}
            >
              Send Reminders
            </Button>
            {/* </Form.Item> */}
          </Col>
        </Row>
      </Modal>
    </>
  )
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import Route from './Route'
import { readCookie, eraseCookie } from './CookieScript'
import {
  COMPANY_ROLE,
  SUPER_ADMIN_ROLE,
  ROLE_KEYWORD,
  EMPLOYEE_ROLE,
} from '../constants'

axios.defaults.headers.common.Authorization = `Bearer ${readCookie(
  'gm360_token',
)}`

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // console.log(error)
    if (error.response.status === 401) {
      eraseCookie('gm360_token')
      window.location.href = '/login'
    }
    return error
  },
)

export const LoginApi = (email = '', password = ''): any => {
  return axios.post(Route.login, {
    email,
    password,
  })
}
// const token = readCookie('gm360_token')
// axios.defaults.headers.common.Authorization = `Bearer ${token}`

export const logout = async () => {
  await axios.get(Route.logout)
  return eraseCookie('gm360_token')
}

export const forgotPassword = (email = ''): any => {
  return axios.post(Route.forgetPassword, {
    email,
  })
}

export const changePassword = (
  password = '',
  rePassword = '',
  userId = '',
  forgotToken = '',
): any => {
  return axios.post(`${Route.resetPassword}/${userId}/${forgotToken}`, {
    password,
    rePassword,
  })
}

// get current employee detail
export const currentEmployeeDetail = (): any => {
  return axios.get(Route.currentEmployeeDetail)
}

// dashboard header data api call
export const DashBoardHeaderApi = (controller: any): any => {
  return axios.get(Route.dasboardHeader, { signal: controller.signal })
}

// dashboard Recent Project api call
export const RecentProjectApi = (data: any, controller: any): any => {
  return axios.post(Route.recentProject, data, { signal: controller.signal })
}

// Company

// export const CompanyListApi = (): any => {
//   return axios.get(Route.companyList)
// }

export const CompanyListApi = (data: any): any => {
  return axios.post(Route.getCompanyList, data)
}

export const createCompany = (data: any): any => {
  return axios.post(Route.createCompany, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const getCompany = async (id: any) => {
  const data = await axios.get(`${Route.company}/${id}`)
  return data
}

export const updateCompany = (data: any, id: any) => {
  return axios.put(`${Route.company}/${id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const removeCompany = (id: any): any => {
  return axios.delete(`${Route.companyDelete}/${id}`)
}

export const changeCompanyMode = (id: any, data: any): any => {
  return axios.post(`${Route.changeCompanyMode}/${id}`, data)
}

export const filterCompanyAPI = (data: any): any => {
  return axios.post(Route.filterCompany, data)
}

// add inquiry
export const createInquiry = (data: any) => {
  return axios.post(Route.addInquiry, data)
}

// Employee
export const getEmployee = (): any => {
  return axios.get(Route.getEmployee)
}

export const updateEmployeeDetail = (data: any, id: any): any => {
  return axios.put(`${Route.updateEmployeeDetail}/${id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// Employee
export const getEmployeeDetail = (id: any): any => {
  return axios.get(`${Route.getEmployeeDetail}/${id}`)
}

export const addEmployee = async (data: any) => {
  const res = await axios.post(Route.employee, data)
  return res
}
export const bulkEmployee = async (data: any) => {
  const res = await axios.post(Route.employee, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return res
}

export const changeEmployeeStatus = (id: any, data: any): any => {
  return axios.post(`${Route.changeEmployeeStatus}/${id}`, data)
}

export const getCompanysEmployees = (data: any) => {
  return axios.post(Route.getCompanysEmployees, data)
}

export const filterCompanysEmployees = (id: any, data: any) => {
  return axios.post(`${Route.filterCompanysEmployees}/${id}`, data)
}

export const filterProjectEmployeesApi = (id: any, data: any) => {
  return axios.post(`${Route.filterProjectEmployees}/${id}`, data)
}

export const filterEmployeeAPI = (data: any): any => {
  return axios.post(Route.filterEmployee, data)
}

// Employee chart Project page => Detail tab
export const EmployeeChartApi = (id: string, controller?: any): any => {
  if (controller) {
    return axios.get(`${Route.getEmployeeChartDetail}/${id}`, {
      signal: controller.signal,
    })
  }
  return axios.get(`${Route.getEmployeeChartDetail}/${id}`)
}

// Raters chart Project page => Detail tab
export const RatersChartApi = (
  id: string,
  data: any,
  controller?: any,
): any => {
  if (controller) {
    return axios.post(`${Route.getRatersChartDetail}/${id}`, data, {
      signal: controller.signal,
    })
  }
  return axios.post(`${Route.getRatersChartDetail}/${id}`, data)
}

// Participant
export const addParticipant = async (data: any) => {
  const res = await axios.post(Route.addParticipant, data)
  return res
}
export const bulkParticipant = async (data: any) => {
  const res = await axios.post(Route.addParticipant, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return res
}

// Project
export const addProject = (data: any): any => {
  return axios.post(Route.addProject, data)
}

export const getAllProject = async (data: any) => {
  const res = await axios.post(Route.getAllProject, data)
  return res
}

export const getProjectDetail = async (id: any) => {
  // console.log(id)
  const res = await axios.get(`${Route.getProjectDetail}/${id}`)
  // console.log(res)
  return res
}
export const removeChartImage = async (id: any) => {
  const res = await axios.get(`${Route.removeChartImage}/${id}`)
  return res
}

export const updateProjectDetail = (id: any, data: any): any => {
  return axios.put(`${Route.updateProjectDetail}/${id}`, data)
}

export const removeProject = (id: any): any => {
  return axios.delete(`${Route.removeProject}/${id}`)
}

export const changeProjectMode = (id: any, data: any): any => {
  return axios.post(`${Route.changeProjectMode}/${id}`, data)
}

export const getProjectsEmployeeApi = (id: any, data: any): any => {
  return axios.post(`${Route.getProjectsEmployee}/${id}`, data)
}

export const filterProjectAPI = (data: any, controller: any): any => {
  if (controller) {
    return axios.post(Route.filterProject, data, { signal: controller.signal })
  }

  return axios.post(Route.filterProject, data)
}

export const getMyProjects = (data: any): any => {
  return axios.post(Route.getMyProjects, data)
}

export const filterMyProjects = (id: string, data: any): any => {
  return axios.post(`${Route.filterMyProjects}/${id}`, data)
}

export const getMyProjectsLists = (id: string): any => {
  return axios.get(`${Route.getMyProjectsLists}/${id}`)
}

export const getMyProjectEmployeeLists = (id: string): any => {
  return axios.get(`${Route.getMyProjectEmployeeLists}/${id}`)
}

// Survey

export const addQuestion = (data: any): any => {
  return axios.post(Route.addQuestion, data)
}

export const getQuestions = (id: any): any => {
  return axios.get(`${Route.getQuestions}/${id}`)
}

export const publishForm = (id: any): any => {
  return axios.put(`${Route.publishForm}/${id}`)
}

export const moveToTrash = (data: any): any => {
  return axios.post(Route.moveToTrash, data)
}

export const restoreFromTrash = (id: any): any => {
  return axios.post(`${Route.restoreFromTrash}/${id}`)
}

export const getSurvey = (id: any, data: any): any => {
  return axios.post(`${Route.getSurvey}/${id}`, data)
}

export const resetSurvey = (surveyId: any, userId: any): any => {
  return axios.post(`${Route.resetSurvey}`, {
    survey_id: surveyId,
    user_id: userId,
  })
}

export const addAnswer = (data: any): any => {
  return axios.post(Route.addAnswers, data)
}

export const updateSurveyReplayStatus = (data: any): any => {
  return axios.post(Route.updateSurveyReplayStatus, data)
}

// FocusArea
export const getAllFocusArea = (id: any): any => {
  return axios.get(`${Route.getAllFocusArea}/${id}`)
}

export const getFocusArea = (id: any): any => {
  return axios.get(`${Route.getFocusArea}/${id}`)
}

export const updateFocusArea = (id: any, data: any): any => {
  return axios.put(`${Route.updateFocusArea}/${id}`, data)
}

export const deleteFocusArea = (id: any): any => {
  return axios.delete(`${Route.deleteFocusArea}/${id}`)
}

export const getConstructs = (id: any): any => {
  return axios.get(`${Route.getConstructs}/${id}`)
}

export const addConstructs = (data: any): any => {
  return axios.post(`${Route.addConstructs}`, data)
}

export const updateConstructs = (id: any, data: any): any => {
  return axios.put(`${Route.updateConstructs}/${id}`, data)
}

export const addFocusArea = (data: any): any => {
  return axios.post(Route.addFocusArea, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

// Raters
export const getAllRaters = (id: any): any => {
  return axios.get(`${Route.getAllRaters}/${id}`)
}

// export const getCompanyRaters = (id: any): any => {
//   return axios.get(`${Route.getCompanyRaters}/${id}`)
// }

export const getRater = (id: any): any => {
  return axios.get(`${Route.getRater}/${id}`)
}

export const addRaters = (data: any): any => {
  return axios.post(`${Route.addRaters}`, data)
}

export const deleteRaters = (id: any): any => {
  return axios.delete(`${Route.deleteRaters}/${id}`)
}

export const updateRater = (id: any, data: any): any => {
  return axios.put(`${Route.updateRater}/${id}`, data)
}

// Company only list name and id
export const getCompanyList = (): any => {
  return axios.get(Route.companyList)
  // return axios.get(Route.getCompanyList)
}

// My report menu table
export const getCompanyReportTableDataApi = (data: any): any => {
  return axios.post(`${Route.getCompanyReportTableData}`, data)
}

export const getEmployeeProjectReportPDF = (id: any, data: any): any => {
  return axios.post(`${Route.getEmployeeProjectReportPDF}/${id}`, data)
}

export const exportDataToCSV = (id: any, data: any): any => {
  return axios.post(`${Route.ExportDataToCSV}/${id}`, data)
}

export const exportDataToCSVProject = (id: any): any => {
  return axios.post(`${Route.ExportDataToCSVProject}/${id}`)
}

export const getProjectReportPDF = (id: any): any => {
  return axios.post(`${Route.getProjectReportPDF}/${id}`)
}

// My reort project table data menu list
export const getReportProjectTableData = (data: any): any => {
  return axios.post(`${Route.myReportProjectTable}`, data)
}

// My reort project table data menu list
export const getEmployeeReportTableDataApi = (data: any): any => {
  return axios.post(`${Route.getEmployeeReportTableData}`, data)
}

export const filterEmployeeReportTableData = (data: any): any => {
  return axios.post(`${Route.filterEmployeeReportTableData}`, data)
}
export const filterCompanyReportTableData = (data: any): any => {
  return axios.post(`${Route.filterCompanyReportTableData}`, data)
}
// get Project Table data in my report expand row
export const getReportProjectNestedTableDataApi = (data: any): any => {
  return axios.post(`${Route.getNestedProjectReportTableData}`, data)
}

// get Project Table data in my report expand row
export const filterReportCompantyApi = (data: any, controller?: any): any => {
  return axios.post(`${Route.filterReportCompanty}`, data, {
    signal: controller.signal,
  })
}

// Distribution api
// get list of distribution

export const getListOfDistribution = (id: any, data: any): any => {
  return axios.post(`${Route.getAllDistribution}/${id}`, data)
}

export const closeDistribution = (id: any, data: any): any => {
  return axios.post(`${Route.closeDistribution}/${id}`, data)
}

// get distribution cascader data
export const getListOfCascader = (id: any): any => {
  return axios.get(`${Route.getDistributionCacader}/${id}`)
}

// add distribution data
export const addDistributionData = (data: any): any => {
  return axios.post(`${Route.addDistribution}`, data)
}

// update distribution data
export const updateDistributionApi = (data: any, id: any) => {
  return axios.put(`${Route.updateDistribution}/${id}`, data)
}

// get employee table data for data analysis tab
export const getEmployeeTaleData = (id: any, data: any): any => {
  return axios.post(`${Route.getEmployeesData}/${id}`, data)
}

// delete employee
export const deleteEmployees = (id: any): any => {
  return axios.delete(`${Route.deleteEmployees}/${id}`)
}
// get employee table data for data analysis tab only if login user is employee
export const getEmployeeUserTaleDataApi = (id: any): any => {
  return axios.get(`${Route.getEmployeeUserTaleData}/${id}`)
}

// get last Shared Remainder for data analysis tab
export const getLastSharedRemainderDataApi = (data: any): any => {
  return axios.post(`${Route.getLastSharedRemainderData}`, data)
}

// get last Shared Remainder for data analysis tab
export const sendRemainderDataApi = (data: any): any => {
  return axios.post(`${Route.sendRemainderData}`, data)
}

// get Raters & Participant list for data analysis Response Modal
export const getRatersAndParticipantsDataApi = (id: any, data: any): any => {
  return axios.post(`${Route.getRatersAndParticipantsData}/${id}`, data)
}

// get single distribution
export const getSingleDistributionApi = (id: any): any => {
  return axios.get(`${Route.getSingleDistribution}/${id}`)
}

// filter distribution
export const distributionsFilter = (id: any, data: any): any => {
  return axios.post(`${Route.distributionsFilter}/${id}`, data)
}

//  get list of Projects
export const getProjectList = (controller: any): any => {
  return axios.post(`${Route.getAllProjectList}`, {
    signal: controller.signal,
  })
}

//  change password from preferences menu
export const changeUserPasswordApi = (data: any): any => {
  return axios.post(`${Route.changeUserPassword}`, data)
}

// project detail-> user -> user (remove paricipant api)
export const removeParticipantApi = (
  participationId: any,
  projectId: any,
): any => {
  return axios.delete(
    `${Route.removeParticipant}/${participationId}/${projectId}`,
  )
}

// get single participation data
export const getSingleParticipationDataApi = (id: any): any => {
  return axios.get(`${Route.getSingleParticipant}/${id}`)
}

// get single participation chart data
export const getParticipantSurveyStatusApi = (data: any): any => {
  return axios.post(`${Route.getParticipantSurveyStatus}`, data)
}

// update participation data
export const updateParticipationApi = (data: any, id: any) => {
  return axios.put(`${Route.updateParticipation}/${id}`, data)
}

// get Rater Survey chart data
export const getRatersSurveyStatusApi = (data: any): any => {
  return axios.post(`${Route.getRatersSurveyStatus}`, data)
}

// get Single Employee Rater Survey chart data
export const getSingleEmployeeSurveyStatusApi = (data: any): any => {
  return axios.post(`${Route.getSingleEmployeeSurveyStatus}`, data)
}

// get employees participant list
export const getAllParticipation = (): any => {
  return axios.post(Route.getAllParticipation)
}

// get company detail -> detail tab -> employee chart data Api
export const getEmployeeSurveyStatusApi = (id: any): any => {
  return axios.get(`${Route.getEmployeeSurveyStatus}/${id}`)
}

// get current login company user
export const getCurrentCompanyApi = (): any => {
  return axios.get(Route.getCurrentCompany)
}
// get current login employee user
// export const getCurrentEmployeeApi = (): any => {
//   return axios.get(Route.currentEmployeeDetail)
// }

export const getDataAndAnalysysFilter = (id: any, data: any): any => {
  if (readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE) {
    return axios.post(`${Route.FilterEmployeeDataAndAnalysis}/${id}`, data)
  }
  return axios.post(`${Route.getDataAndAnalysysFilter}/${id}`, data)
}

export const createTemplate = (data: any): any => {
  return axios.post(Route.createTemplate, data)
}

export const createTemplateFocusArea = (data: any): any => {
  return axios.post(Route.createTemplateFocusArea, data)
}

export const getAllFocusAreaTemplate = (data: any): any => {
  return axios.post(Route.getAllFocusAreaTemplate, data)
}

// get list of templates
export const getTemplateTableDataApi = (data: any): any => {
  return axios.post(Route.getTemplateTableData, data)
}

export const importTemplateFocusArea = (data: any): any => {
  return axios.post(Route.importTemplateFocusArea, data)
}

export const getListOfAllTemplate = (data: any): any => {
  return axios.post(Route.getListOfAllTemplate, data)
}

export const importMainTemplate = (data: any): any => {
  return axios.post(Route.importMainTemplate, data)
}

export const filterTemplateApi = (data: any): any => {
  return axios.post(Route.filterTemplate, data)
}

export const deleteTampateApi = (id: any): any => {
  return axios.delete(`${Route.deleteTemplate}/${id}`)
}

export const addChartImageApi = (data: any): any => {
  return axios.post(Route.addChartImage, data)
}

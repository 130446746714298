/* eslint-disable import/extensions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { Drawer, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { FilterBar } from './tabs/components/projectList/filterbar'
import { ProjectGrid } from './tabs/components/projectList/projectGrid'
import { ProjectListView } from './tabs/components/projectList/projectList'
import rightArrow from '../../assets/images/Icon ionic-ios-arrow-back.svg'
import userIcon from '../../assets/images/Group 9264.svg'
import './style/myProject.scss'
import { AddProject } from './components/addproject'
import DrawerFooter from '../../components/Drawer/footer/drawerFooter'
import {
  getAllProject,
  logout,
  removeProject,
  changeProjectMode,
  filterProjectAPI,
} from '../../api/API'
import { storeProjectInfoAction } from '../../reduxActions/projectAction'
import Image from '../../components/Image'
import Label from '../../components/Label'
import addCompany from '../../assets/images/addCompany.svg'
import { readCookie } from '../../api/CookieScript'
import {
  CREATE_NEW,
  ROLE_KEYWORD,
  DO_NOT_HAVE_PROJECT,
  SUPER_ADMIN_ROLE,
  COMPANY_ROLE,
} from '../../constants'

export const projectList: React.FC = (): JSX.Element => {
  const history = useHistory()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [viewType, setviewType] = useState<any>()
  const [projectListdata, setProjectList] = useState([])
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const controller = new AbortController()
  // const projectDataInfo = useSelector((state) => state.projectInfo)

  useEffect(() => {
    getListofProjects()
    // console.log(projectDataInfo)
    return () => {
      controller.abort() // <-- 3rd step
    }
  }, [currentPage])

  const getListofProjects = (): void => {
    setLoading(true)
    const data = {
      page_no: currentPage,
    }
    getAllProject(data)
      .then((res: any): any => {
        setProjectList(res.data.data.docs)
        dispatch(storeProjectInfoAction(res.data.data.docs))
        setLoading(false)
        setTotalPages(res.data.data.totalPages)
        if (res.data.data.docs) {
          setviewType(
            <ProjectGrid
              projectList={res.data.data.docs}
              deleteProject={deleteProject}
              changeMode={changeMode}
            />,
          )
        }
      })
      .catch((error: any) => {
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
          }
        }
      })
  }

  const deleteProject = (id: any): void => {
    removeProject(id)
      .then((res: any): any => {
        getListofProjects()
      })
      .catch((error: any) => {
        getListofProjects()
      })
  }

  const changeMode = (id: any, mode: boolean): void => {
    const data = {
      mode,
    }
    changeProjectMode(id, data)
      .then((res: any): any => {
        getListofProjects()
      })
      .catch((error: any) => {
        getListofProjects()
      })
  }

  const filterProject = (key: string, value: any): void => {
    const existingQury = filterQuery

    if (key === 'name' && value.target.value !== '') {
      existingQury[key] = value.target.value
    }
    if (key === 'mode') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }

    if (key === 'company') {
      existingQury[key] = value
    }

    if (key === 'date_added') {
      existingQury[key] = value
    }

    if (key === 'name' && value.target.value === '') {
      getListofProjects()
    }

    setFilterQuery(existingQury)

    filterProjectAPI(existingQury, controller)
      .then((res: any): any => {
        setProjectList(res.data.data)
        setviewType(
          <ProjectGrid
            projectList={res.data.data}
            deleteProject={deleteProject}
            changeMode={changeMode}
          />,
        )
      })
      .catch((error: any) => {
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
          }
        }
      })
  }

  const resetFilter = (): void => {
    getListofProjects()
  }

  const changeViewType = (type: string): any => {
    if (type === 'grid') {
      setviewType(
        <ProjectGrid
          projectList={projectListdata}
          deleteProject={deleteProject}
          changeMode={changeMode}
        />,
      )
    } else {
      setviewType(
        <ProjectListView
          projectList={projectListdata}
          deleteProject={deleteProject}
        />,
      )
    }
  }

  const onClose = function (): void {
    setDrawerVisible(false)
  }
  return (
    <div className="project-section-container min-85vh">
      {loading && <Spin className="spinner-code" tip="Loading..." />}
      <div className="projects-header">
        <div className="project-header-title">
          <span className="project-title text-uppercase">My projects</span>
          <div className="project-header-links">
            {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
              <>
                <Link to="/dashboard">
                  <span className="project-link">Dashboard</span>
                </Link>
                <img
                  src={rightArrow}
                  alt="rightArrow"
                  className="project-rightArrow"
                />
              </>
            )}
            <Link to={{ pathname: `/my-project/` }}>
              <span className="project-link">My Projects</span>
            </Link>
          </div>
        </div>
        <div className="project-header-button">
          {/* {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
            readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && ( */}
          {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
            <Button
              onClick={() => setDrawerVisible(true)}
              className="project-button"
            >
              ADD New Project
            </Button>
          )}
        </div>
      </div>
      <div className="project-list-container">
        <FilterBar
          changeViewType={changeViewType}
          filterProject={filterProject}
          resetFilter={resetFilter}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
        <hr />
        {viewType}
      </div>

      {/* only company and super admin user can add new project    */}
      {/* {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
        readCookie(ROLE_KEYWORD) === COMPANY_ROLE) &&
        projectListdata.length === 0 &&
        !loading && ( */}
      {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE &&
        projectListdata.length === 0 &&
        !loading && (
          // <p className="text-center h-100">Projects Not Available</p>
          <div className="company-main">
            <div className="company-main-section">
              <Image
                src={addCompany}
                alt="Add Company"
                style={{ marginBottom: '20px', maxWidth: '100px' }}
              />
              <Label
                title={DO_NOT_HAVE_PROJECT}
                style={{
                  marginBottom: '20px',
                  fontSize: '1rem',
                  fontFamily: 'Calibri',
                  color: '#b6becc',
                  textAlign: 'center',
                }}
              />
              <Button
                onClick={() => setDrawerVisible(true)}
                className="create-project-btn"
              >
                <span className="material-icons">add</span>
                <span className="create-new-company">{CREATE_NEW}</span>
              </Button>
            </div>
          </div>
        )}

      <div className="drawer-wrapper">
        <Drawer
          onClose={() => {
            setDrawerVisible(false)
          }}
          visible={drawerVisible}
          title={
            <>
              <img src={userIcon} className="drawer-head-icon" alt="user" />
              <span className="ms-2">ADD NEW PROJECT</span>
            </>
          }
          width={window.innerWidth > 550 ? 465 : '100%'}
          footer={<DrawerFooter />}
        >
          <AddProject
            setDrawerVisible={setDrawerVisible}
            getListofProjects={getListofProjects}
          />
        </Drawer>
      </div>
    </div>
  )
}

/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { Row, Col, Button, Cascader, notification } from 'antd'
import { useParams } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import {
  getListOfCascader,
  addDistributionData,
  getAllRaters,
} from '../../../../../api/API'
import 'antd/dist/antd.css'

// const Quill = ReactQuill.Quill
const Size = Quill.import('attributors/style/size')
const sizeList = ['10px', '12px', '14px']
Size.whitelist = sizeList
Quill.register(Size, true)

const Font = Quill.import('attributors/class/font')
const fontList = [
  'Calibri Regular',
  'Calibri Bold',
  'Calibri Italic',
  'Calibri Bold Italic',
  'Calibri Light',
  'Calibri Light Italic',
  'serif',
  'sans-serif',
  'monospace',
  'cursive',
  'fantasy',
  'system-ui',
  'ui-serif',
  'ui-sans-serif',
  'ui-monospace',
  'ui-rounded',
  'emoji',
  'math',
  'fangsong',
]
Font.whitelist = fontList.map((font) => getFontName(font))
Quill.register(Font, true)

let fontStyles = ''
fontList.forEach(function (font) {
  const fontName = getFontName(font)
  fontStyles +=
    `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before {` +
    `content: '${font}';` +
    `font-family: '${font}', sans-serif;` +
    `}` +
    `.ql-font-${fontName}{` +
    ` font-family: '${font}', sans-serif;` +
    `}`
})

let sizeStyles = ''
sizeList.forEach(function (size) {
  const sizeName = size
  sizeStyles +=
    `.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=${sizeName}]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=${sizeName}]::before {` +
    `content: '${size}';` +
    `font-size: '${size}' !important;` +
    `}` +
    `.ql-size-${sizeName}{` +
    ` font-size: '${size}' !important;` +
    `}`
})

function getFontName(font: any): any {
  return font.toLowerCase().replace(/\s/g, '-')
}

const node = document.createElement('style')
node.innerHTML = fontStyles
node.innerHTML = sizeStyles
document.body.appendChild(node)

interface Props {
  setUpdateTableData: any
}

export const ProjectDetailFrom: React.FC<Props> = ({
  setUpdateTableData,
}): JSX.Element => {
  const params = useParams<any>()
  const { id } = params // project id

  const dateFormat = 'YYYY/MM/DD'
  const [raterlist, setRaterList] = useState<any>([])
  const [selfRaterID, setSelfRaterID] = useState('')
  // const [to, setTo] = useState([])
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [directMailSending, setDirectMailSending] = useState<boolean>(false)

  const [fromName, setFromName] = useState('')
  const [fromEmail, setFromEmail] = useState('test@gmail.com')
  const [replyToEmail, setReplyToEmail] = useState('test@gmail.com')
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')
  const [cascader, setCascader] = useState([{}])
  const [originalAPIdata, setOriginalAPIdata] = useState<any>([])
  const [selectedEmpId, setSelectedEmpId] = useState<any>([])
  const [selectedParticipantId, setSelectedParticipantId] = useState<any>([])
  const [rerenderState, setRerenderState] = useState(Math.random())

  // Error Stats
  const [cascaderErr, setCascaderErr] = useState('')
  const [dateErr, setDateErr] = useState('')
  const [timeErr, setTimeErr] = useState('')
  const [fromEmailErr, setFromEmailErr] = useState('')
  const [fromNameErr, setFromNameErr] = useState('')
  const [replyToEmailErr, setReplyToEmailErr] = useState('')
  const [subjectErr, setSubjectErr] = useState('')
  const [flag, setFlag] = useState(false)
  // const [descriptionErr, setDescriptionErr] = useState('')

  const today = new Date()
  const hr = today.getHours()
  const min =
    today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
  const defaultTime = `${hr}:${min}`
  const defaultDate = today.toLocaleDateString('en-CA')
  useEffect(() => {
    if (directMailSending) {
      setTime(defaultTime)
      setDate(defaultDate)
    }
  }, [directMailSending])

  useEffect(() => {
    getAllRaters(params.id).then((res: any) => {
      setRaterList(res.data.data)
      const result = res.data.data
      const selfData = result.find(
        (rater: any) => rater.title.toLowerCase() === 'self',
      )
      setSelfRaterID(selfData._id)
    })
  }, [])

  // fill api values in cascader
  const getCascaderData = (): any => {
    getListOfCascader(id).then((res: any): any => {
      setOriginalAPIdata(res.data.data)
      // console.log("res.data.data=>>",res.data.data);
      const casdata = res.data.data
      const dataMap: any = [] // { code: 'all', name: 'all' }
      for (let i = 0; i < casdata.length; i++) {
        const subdata = []
        for (let j = 0; j < casdata[i].raters.length; j++) {
          // if(casdata[i].raters[j].title != 'Self'){
          const patlist = []
          for (let k = 0; k < casdata[i].raters[j].participant.length; k++) {
            patlist.push({
              code: casdata[i].raters[j].participant[k]._id,
              name: casdata[i].raters[j].participant[k].name,
            })
          }
          subdata.push({
            code: casdata[i].raters[j]._id,
            name: casdata[i].raters[j].title,
            items: patlist,
          })
          // }
        }

        dataMap.push({
          code: casdata[i]._id,
          name: casdata[i].first_name,
          items: subdata,
        })
      }
      setCascader(dataMap)
    })
  }

  useEffect(() => {
    getCascaderData()

    setTimeout(() => {
      setRerenderState(Math.random())
    }, 100)
  }, [flag])

  // cascader search filter
  const filter = (inputValue: any, path: any): any => {
    return path.some(
      (option: any) =>
        option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    )
  }
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  // handle Submit Method
  const handleSubmit = (): any => {
    console.log('call 111')
    console.log('selectedEmpId', selectedEmpId)
    console.log('date', date)
    console.log('selectedParticipantId', selectedParticipantId)
    console.log('time', time)
    console.log('fromName', fromName)
    console.log('subject', subject)
    console.log('fromEmail', fromEmail)
    console.log('replyToEmail', replyToEmail)

    setCascaderErr('')
    setDateErr('')
    setTimeErr('')
    setFromNameErr('')
    setSubjectErr('')
    setFromEmailErr('')
    setReplyToEmailErr('')

    // check Validation
    let valid = true
    // console.log(selectedEmpId, selectedParticipantId)
    const totalLength = selectedEmpId.length + selectedParticipantId.length
    if (totalLength == 0) {
      setCascaderErr('Please Select Employee,Participant')
      valid = false
    }
    // if (selectedEmpId.length == 0 && selectedParticipantId.length == 0) {
    //   setCascaderErr('Please Select Employee,Participant')
    //   valid = false
    // }
    if (date == '') {
      setDateErr('Please Select Date')
      valid = false
    }
    if (time == '') {
      setTimeErr('Please Select Time')
      valid = false
    }
    if (fromName == '') {
      setFromNameErr('Please Enter From Name')
      valid = false
    }
    if (subject == '') {
      setSubjectErr('Please Enter Valid Subject')
      valid = false
    }
    if (fromEmail == '' || !fromEmail.match(emailRegex)) {
      setFromEmailErr('Please Enter Valid Email')
      valid = false
    }
    if (replyToEmail == '' || !replyToEmail.match(emailRegex)) {
      setReplyToEmailErr('Please Enter Valid Email')
      valid = false
    }
    if (valid) {
      const formdata = {
        project_id: id,
        employee: selectedEmpId,
        participants: selectedParticipantId,
        directMailSending,
        date,
        time,
        form_name: fromName,
        from_email: fromEmail,
        replay_to_email: replyToEmail,
        subject,
        description,
      }
      console.log('formdata', formdata)
      // pass distribution data to api
      addDistributionData(formdata).then((res: any): any => {
        if (res.status !== 200) {
          notification.error({
            message: 'something went wrong refresh pages',
          })
        } else {
          setCascaderErr('')
          setDateErr('')
          setTimeErr('')
          setFromNameErr('')
          setSubjectErr('')
          setFromEmailErr('')
          setReplyToEmailErr('')

          setRerenderState(Math.random())
          setFlag(!flag)
          setUpdateTableData(true)
          setDate('')
          setTime('')
          setFromName('')
          setDirectMailSending(false)
          // setFromEmail('')
          // setReplyToEmail('')
          setSubject('')
          setDescription('')
          setSelectedEmpId([])
          setSelectedParticipantId([])
          // history.push('/project-detail')
          notification.success({
            message: 'Distribution successfully Added',
          })
        }
      })
      // api function over
    } // if over
  }
  const modules = {
    toolbar: [
      // [{ header: '1' }, { header: '2' }],
      [{ font: Font.whitelist }],
      [{ size: Size.whitelist }],
      [{ header: [1, 2, 3, 4, 5, 6, true] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  }
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  // const handleSubmit2 = (): void => {
  //   console.log(selectedEmpId)
  //   console.log(selectedParticipantId)
  // }

  const options = cascader
  // function to store selected employee and participation id

  const cascaderFun = (choosenoption: any): any => {
    const empIds = []
    const patIds = []
    for (let index = 0; index < choosenoption.length; index++) {
      const element = choosenoption[index]

      if (element.length > 1) {
        // set participation Ids in state
        // const participationid = element.length === 3 ? element[2] : element[1]
        // if (patIds.indexOf(participationid) === -1) {
        //   patIds.push(participationid)
        // }
        // // set employee Ids in state
        // const employeeid = element[0]
        // if (empIds.indexOf(employeeid) === -1) {
        //   empIds.push(employeeid)
        // }

        if (element.length === 3) {
          const participationid = element[2]
          if (patIds.indexOf(participationid) === -1) {
            patIds.push(participationid)
          }
        }

        if (element.length === 2) {
          for (let index2 = 0; index2 < originalAPIdata.length; index2++) {
            if (originalAPIdata[index2]._id === element[0]) {
              for (
                let index3 = 0;
                index3 < originalAPIdata[index2].raters.length;
                index3++
              ) {
                for (
                  let index4 = 0;
                  index4 <
                  originalAPIdata[index2].raters[index3].participant.length;
                  index4++
                ) {
                  if (
                    element[1].toString() ===
                    originalAPIdata[index2].raters[index3]._id.toString()
                  ) {
                    patIds.push(
                      originalAPIdata[index2].raters[index3].participant[index4]
                        ._id,
                    )
                  }
                  // else {
                  //   patIds.push(
                  //     originalAPIdata[index2].raters[index3].participant[index4]
                  //       ._id,
                  //   )
                  // }
                }
              }
            }
          }
        }

        const employeeid = element[0]
        const selfid = element[1]
        if (selfRaterID === selfid) {
          if (empIds.indexOf(employeeid) === -1) {
            empIds.push(employeeid)
          }
        }
      } else {
        // set employee Ids in state
        const employeeid = element[0]
        if (empIds.indexOf(employeeid) === -1) {
          empIds.push(employeeid)
        }

        for (let index2 = 0; index2 < originalAPIdata.length; index2++) {
          if (originalAPIdata[index2]._id === employeeid) {
            for (
              let index3 = 0;
              index3 < originalAPIdata[index2].raters.length;
              index3++
            ) {
              for (
                let index4 = 0;
                index4 <
                originalAPIdata[index2].raters[index3].participant.length;
                index4++
              ) {
                patIds.push(
                  originalAPIdata[index2].raters[index3].participant[index4]
                    ._id,
                )
              }
            }
          }
        }
      }
    }

    console.log('empIds', empIds)
    console.log('patIds', patIds)

    setSelectedEmpId(empIds)
    setSelectedParticipantId(patIds)
  }
  const disablePastDate = (): any => {
    const dtToday = new Date()
    let month: any = dtToday.getMonth() + 1
    let day: any = dtToday.getDate()
    const year: any = dtToday.getFullYear()
    if (month < 10) month = `0${month.toString()}`
    if (day < 10) day = `0${day.toString()}`
    return `${year}-${month}-${day}`
  }

  return (
    <div className="border p-3 bg-gray-color project-form">
      <b>Distribution Details</b>
      <p>Please fill in the below details for launching the project</p>
      <Form className="mt-4" key={rerenderState}>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={24} lg={24}>
            <Form.Group className="cascaderStyle">
              <Form.Label>
                Email To
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Cascader
                key="fromControllTo"
                fieldNames={{ label: 'name', value: 'code', children: 'items' }}
                options={options}
                defaultValue={selectedEmpId}
                // selectAll
                onChange={cascaderFun}
                // onChange={cascaderFun}
                multiple
                placeholder="Please select"
                className="w-100"
                showSearch={{ filter }}
                onSearch={(name) => console.log(name)}
              />
              <label className="text-danger">{cascaderErr}</label>
            </Form.Group>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Group className="mb-xs-0 mb-xl-3">
              {/* <Form.Label>
                  Direct Mail Sending          
              </Form.Label> */}
              <Form.Check
                key="fromControllDirectMailSending"
                type="checkbox"
                checked={directMailSending}
                onChange={(e) => setDirectMailSending(e.target.checked)}
                style={{ float: 'left' }}
              />{' '}
              <b>Send Now</b>
            </Form.Group>
          </Col>

          <Col xs={12} md={12} lg={12}>
            <Form.Group className="mb-xs-0 mb-xl-3">
              <Form.Label>
                Date
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                key="fromControllDate"
                type="date"
                className="inputBorder"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                disabled={directMailSending}
                min={disablePastDate()}
              />
              <label className="text-danger">{dateErr}</label>
            </Form.Group>
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Form.Group className="mb-xs-0 ">
              <Form.Label>
                Time
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                key="fromControllTime"
                type="time"
                className="inputBorder"
                value={time}
                disabled={directMailSending}
                onChange={(e) => setTime(e.target.value)}
              />
              <label className="text-danger">{timeErr}</label>
            </Form.Group>
          </Col>
          {/* </Row>
          <Row gutter={[16, 16]}> */}
          {/* <Col xs={24} md={10} lg={10} xl={12}>
            <Form.Group className="mb-xs-0 mb-xl-3">
              <Form.Label>
                From
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                key="fromControllToEmail"
                type="email"
                placeholder="Email"
                className="inputBorder"
                // defaultValue={fromEmail}
                onChange={(e) => setFromEmail(e.target.value)}
              />
              <label className="text-danger">{fromEmailErr}</label>
            </Form.Group>
          </Col> */}
          <Col xs={24} md={24} lg={24} xl={24}>
            <Form.Group className="mb-xs-0 mb-xl-3">
              <Form.Label>
                From Name
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                key="fromControllName"
                type="text"
                placeholder="Name"
                className="inputBorder"
                defaultValue={fromName}
                onChange={(e) => setFromName(e.target.value)}
              />
              <label className="text-danger">{fromNameErr}</label>
            </Form.Group>
          </Col>
          {/* </Row>
          <Row gutter={[16, 16]}> */}
          {/* <Col xs={24} md={24} lg={24}>
            <Form.Group className="mt-0">
              <Form.Label>
                Reply To
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                key="fromControllFromEmail"
                type="email"
                placeholder="Reply To"
                className="inputBorder"
                defaultValue={replyToEmail}
                onChange={(e) => setReplyToEmail(e.target.value)}
              />
              <label className="text-danger">{replyToEmailErr}</label>
            </Form.Group>
          </Col> */}
          <Col xs={24} md={24} lg={24}>
            <Form.Group className="mt-0">
              <Form.Label>
                Subject
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                key="fromControllSubject"
                type="text"
                placeholder="Survey for new project"
                className="inputBorder"
                defaultValue={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <label className="text-danger">{subjectErr}</label>
            </Form.Group>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              {/* <Form.Control as="textarea" rows={8} className="inputBorder" /> */}
              <ReactQuill
                key="fromControllDescription"
                theme="snow"
                formats={formats}
                modules={modules}
                className="inputBorder"
                defaultValue={description || ''}
                onChange={(e: any) => setDescription(e)}
              />
            </Form.Group>
            <h4>Shortcode</h4>
            {/* <table>
              <tbody>
                <tr>
                  <td>First Name: </td>
                  <td>&#36;&#123;first_name&#125;</td>
                </tr>
                <tr>
                  <td>Last Name: </td>
                  <td>&#36;&#123;last_name&#125;</td>
                </tr>
                <tr>
                  <td>SurveyURL: </td>
                  <td>&#36;&#123;l://SurveyURL&#125;</td>
                </tr>
                <tr>
                  <td>SurveyLink: </td>
                  <td>&#36;&#123;l://SurveyLink&#125;</td>
                </tr>
              </tbody>
            </table> */}
            <h5 className="text-break">
              &#36;&#123;first_name&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;last_name&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;l://SurveyURL&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;l://SurveyButton&#125;&nbsp;&nbsp;&nbsp;&#36;&#123;employee_name&#125;
            </h5>
            <div className="text-end" style={{ float: 'right' }}>
              <Button
                className="comman-button btn-theme"
                onClick={handleSubmit}
              >
                Launch
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

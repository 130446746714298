/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import './styles/raterStats.scss'
import { useParams } from 'react-router-dom'
import { PieChart, Pie, Cell } from 'recharts'
import { Select } from 'antd'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap'
import arrowDown from '../../../../../assets/images/arrow_down.svg'
import {
  getMyProjectsLists,
  getAllRaters,
  RatersChartApi,
  getRater,
} from '../../../../../api/API'

interface Props {
  // percentage?: number
  // data?: any[]
  company: any
}
const { Option } = Select

export const RaterChart: React.FC<Props> = ({
  // percentage,
  // data,
  company,
}): JSX.Element => {
  const params = useParams<any>()
  const { id } = params // get company id from url
  const [projectList, setProjectList] = useState([])
  const [raterlist, setRaterList] = useState<any>([])
  const [raterlistdata, setRaterListData] = useState<any>()
  const [projectId, setProjectId] = useState('')
  const [defaultProject, setDefaultProject] = useState('')
  const [defaultRater, setDefaultRater] = useState('')

  const [completeSurveyChartColor, setCompleteSurveyColor] = useState('#febe13')
  const [notCompleteSurveyChartColor, setNotCompleteSurveyColor] =
    useState('#eeeee4')

  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(100)

  const [ratersDropdownVisible, setRatersDropdownVisible] = useState(false)

  useEffect(() => {
    // get project list based on given company id
    getMyProjectsLists(id).then((res: any) => {
      // setDefaultProject(res.data.data[0].name)
      setProjectList(res.data.data)
      // setProjectId(res.data.data[0]._id)
      // getRaterChartData(res.data.data[0]._id, id)
    })
  }, [])

  // get raters category chart list
  const getRaterListData = (pid: any): any => {
    setRatersDropdownVisible(true)
    getAllRaters(pid).then((res: any) => {
      setRaterList(res.data.data)
      setProjectId(pid)
      // setDefaultRater(res.data.data[0].title)
      setDefaultRater('')
      getRaterChartData('all_raters', pid)
    })
  }

  // get Raters Chart Data
  const getRaterChartData = (raterscategory: any, projectid?: any): any => {
    const obj = {
      raterscategory,
    }
    getRater(raterscategory).then((res: any) => {
      setCompleteSurveyColor(res.data.data.color)
      // setNotCompleteSurveyColor('');
    })
    RatersChartApi(projectId || projectid, obj).then((res: any): any => {
      setCompleteSurvey(res.data.data.completedSurveyStatus)
      setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
    })
  }

  // fill api data into chart
  const data = [
    {
      name: 'Not Completed Survey',
      survey: 100 - completeSurvey,
      color: notCompleteSurveyChartColor,
    },
    {
      name: 'Completed Survey',
      survey: completeSurvey === null ? setCompleteSurvey(0) : completeSurvey,
      color: completeSurveyChartColor,
    },
  ]
  // const ndata = data === undefined ? [] : data

  return (
    <div className="rater-pie-wrapper">
      <div className="rater-statsbody">
        <div className="rater-dropdown-wrapper">
          {/* project list based on company id */}
          <Select
            // placeholder="Project List"
            placeholder={defaultProject || 'Select Project'}
            className="p-1 w-25 float-left rater-chart-dropdown ms-md-3 ms-xl-3 ms-lg-3"
            id="dropdown-basic2"
            onChange={(e) => getRaterListData(e)}
          >
            {/* <Option value="">Select Project</Option> */}
            {projectList.length > 0 &&
              projectList.map((item: any) => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
          </Select>

          {/* display raters category of given project id  */}
          {ratersDropdownVisible && (
            <Select
              // placeholder="Raters Category"
              placeholder={defaultRater || 'Select Raters'}
              className="p-1 rater-allproject-dropdown w-25 me-md-3 me-xl-3 me-lg-3"
              onChange={(e) => getRaterChartData(e)}
              defaultValue="all_raters"
            >
              {/* {raterlistdata}               */}

              <Option value="all_raters" selected>
                All Raters
              </Option>
              {raterlist.length > 0 &&
                // eslint-disable-next-line eqeqeq
                raterlist.map((category: any) =>
                  // eslint-disable-next-line eqeqeq
                  category.title != 'Self' ? (
                    <Option key={category._id} value={category._id}>
                      <div className="d-flex align-items-center">
                        <div
                          style={{
                            height: '10px',
                            width: '10px',
                            marginLeft: '10px',
                            backgroundColor: category.color,
                          }}
                        />
                        <div className="ms-2">{category.title}</div>
                      </div>
                    </Option>
                  ) : null,
                )}
            </Select>
          )}
        </div>
        <div className="rater-pie">
          <PieChart width={370} height={370} className="">
            <Pie
              data={data}
              dataKey="survey"
              outerRadius={110}
              innerRadius={55}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <span className="rater-percent">{completeSurvey}%</span>
        </div>
      </div>
      <div className="pie-label-wrapper">
        <div className="d-flex">
          <div className="rater-dot1 me-3" />
          <span className="rater-label-text">Not Completed Survey</span>
        </div>
        <div className="d-flex">
          <div
            className="rater-dot2 me-3"
            style={{ backgroundColor: completeSurveyChartColor }}
          />
          <span className="rater-label-text">Completed Survey</span>
        </div>
      </div>
    </div>
  )
}
// RaterChart.defaultProps = {
//   percentage: 75,
//   data: [
//     { name: 'Not Completed Survey', survey: 25, color: '#fedd84' },
//     { name: 'Completed Survey', survey: 75, color: '#febe13' },
//   ],
// }
